import { useState } from "react";
import FormInputComponent from "../Components/FormInputComponent";
import FormLabelComponent from "../Components/FormLabelComponent";
import Layout from "../Components/Layout/Layout";
import MainButton from "../Components/MainButton";
import FormTextAreaComponent from "../Components/FormTextAreaComponent";
import FooterMenu from "../Components/FooterMenu";
import { isValidEmail } from "../Helpers/StringHelper";
import FormInputError from "../Components/FormInputError";
import LoaderComponent from "../Components/LoaderComponent";
import axios from 'axios'

const Contact = () => {

const [isLoading, setIsLoading] = useState(false);

const [email,setEmail] = useState('')
const [emailError,setEmailError] = useState('')

const [subject,setSubject] = useState('')
const [subjectError,setSubjectError] = useState('')

const [message,setMessage] = useState('')
const [messageError,setMessageError] = useState('')


const [formError,setFormError] = useState('')
const [isSent,setIsSent] = useState(false)

const submitForm = async () => {

    if(isLoading) return;

    let isValid = true;
    setEmailError('')
    setSubjectError('')
    setMessageError('')
    setFormError('')
    if(!isValidEmail(email)) {
        setEmailError('Please enter valid email')
        isValid = false
    }
    if(subject.length < 3) {
        setSubjectError("Please enter your subject")
        isValid = false
    }
    if(message.length < 3) {
        setMessageError("Please enter your message")
        isValid = false
    }
    if(isValid) {
        setIsLoading(true)
        try {
            const response = await axios.post(process.env.REACT_APP_SERVER_URL+'api/general/contact',{
                email: email,
                subject: subject,
                text: message
            },{withCredentials:true})
            if(response.data.status === true) {
                setIsSent(true)
            } else {
                setFormError('Could not send message now, please try again later')
                setIsLoading(false)
            }
        } catch(err) {
            setFormError('Could not send message now, please try again later')
            setIsLoading(false)
        }
    }
}

const getForm = () => {
    if(isSent) return <p className="text-green-600 text-sm">Your message has been received, thank you!</p>
    return <form action="javascript:void(0);" className="space-y-8">
    <div>
        <FormLabelComponent error={emailError} forHtml="email">Your email</FormLabelComponent>
        <FormInputComponent error={emailError} placeholder="Your email address" type="email" id="email" value={email} onChange={(value)=>setEmail(value.target.value)} required />
        <FormInputError error={emailError} />
    </div>
    <div>
        <FormLabelComponent error={subjectError} forHtml="subject">Subject</FormLabelComponent>
        <FormInputComponent error={subjectError} placeholder="Let us know how we can help you" type="subject" id="subject" value={subject} onChange={(value)=>setSubject(value.target.value)} required />
        <FormInputError error={subjectError} />
    </div>
    <div className="sm:col-span-2">
        <FormLabelComponent error={messageError} forHtml="message">Your message</FormLabelComponent>
        <FormTextAreaComponent error={messageError} id="message" placeholder="Leave a comment..." rows={6} value={message} onChange={(value)=>setMessage(value.target.value)}/>
        <FormInputError error={messageError} />
    </div>
    <FormInputError error={formError} />
    {isLoading ? <LoaderComponent /> : <MainButton onClick={() => submitForm()} text="Send message" />}
</form>
}

return (<Layout>
    <div className='mt-6 flex flex-col container mx-auto items-center justify-center inset-x-0 absolute z-10'>

    <h2 className="text-white text-3xl mb-6 delay-2000 transition transition-opacity duration-1000">Contact us</h2>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <p className="mb-8 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">Let us know how we can help you, we will get in touch with you as soon as possible</p>
            {getForm()}
        </div>
        <FooterMenu />
    </div>
</Layout>)

    
};
export default Contact;