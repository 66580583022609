import { React } from 'react'
import styles from "./Weather.module.css";
import SingleDayWeather from './SingleDayWeather'

const TripWeather = ({tripWeather}) => {
    return (
        <div className={styles.tripWeatherContainer}>
            {tripWeather.map(
                (dailyWeather, index) => dailyWeather && <SingleDayWeather key={index} dailyWeather={dailyWeather}/>)}
        </div>
    )
}

export default TripWeather;