import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTicket } from '@fortawesome/free-solid-svg-icons'
import TicketCard from "./TicketCard"
const OrdersList = ({orders}) => {

    const getOrderData = (rawData) => {
        return JSON.parse(rawData)
    }

    if(orders.length < 1) return <></>
    return <div className='flex flex-col gap-6'>{orders.map((order, index) => {
        const orderData = getOrderData(order.rawData)
        return <TicketCard key={index} order={orderData} />
    })}</div>

}

export default OrdersList