
import { CountryDropdown} from 'react-country-region-selector';

const FormCountrySelectorComponent = ({
    value,
    onChange,
    error = ""
}) => {
    const getClasses = () => {
        if(error !== "")
            return "bg-gray-50 border border-red-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        else
            return "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    }


    return <>
        <CountryDropdown classes={getClasses()}
                            value={value}
                            valueType={"short"}
                            onChange={onChange} />
    </>
}

export default FormCountrySelectorComponent;