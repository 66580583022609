import FormLabelComponent from './../FormLabelComponent'
import FormInputComponent from './../FormInputComponent'
import FormInputError from './../FormInputError'
import FormSelectComponent from './../FormSelectComponent'
import FormDateComponent from './../FormDateComponent'
import React, { useState } from 'react';
import { Link } from '@material-ui/core'
import MainButton from '../MainButton'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faChild } from '@fortawesome/free-solid-svg-icons'
import FormCountrySelectorComponent from '../FormCountrySelectorComponent'

const PassengerForm = ({
    passengers,
    passengerErrorList = [],
    onChange = (passengerKey, key, val) => {},
    onSuccess = () => {},
    onBackClick = () => {}
}) => {

    const genderOptions = [
        {label: "Male", value: "male"},
        {label: "Female", value: "female"},
        {label: "-- Select --", value: ""},
    ]


  const [passengerFormExpanded, setPassengerFormExpanded] = useState([0])


    const togglePassengerForm = (index) => {

        const elem = passengerFormExpanded.indexOf(index);
        const newPass = passengerFormExpanded
        if(elem !== -1) {
            const filteredPassengers = newPass.filter((element) => element !== index);
            setPassengerFormExpanded(filteredPassengers);
        } else {

            setPassengerFormExpanded([index]);

            //const updatedPassengers = [...newPass, index]; 
            //setPassengerFormExpanded(updatedPassengers);
        }
    }

    return <>
    <div className='flex justify-between'>
    <h5 className="text-xl font-bold mb-2">Passenger info</h5>
    <Link onClick={onBackClick}>Back</Link>
    </div>
    {passengerErrorList.map((error, index) => {
        return <><div key={index} className='text-red-500'>{error}</div></>
    })}
    <div className='flex mb-4 gap-4 flex-wrap'>
    {passengers.map((passenger, index) => {
        return <div key={index} onClick={() => togglePassengerForm(index)} className={"cursor-pointer max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow"+(passengerFormExpanded.includes(index) ? ' border-green-600': '')}>
            <div className='flex gap-2 mb-4 items-center'>{passenger.type == "adult" ? <FontAwesomeIcon icon={faUser} /> : <FontAwesomeIcon icon={faChild} />} <span className="text-md">Passenger {index + 1}</span></div>
            <div className='flex gap-1'>
                <span className='text-gray-400 text-xs'>Name:</span>
                <span className='text-gray-400 text-xs'>{passenger.firstName!=="" && passenger.lastName!=="" ?  passenger.firstName+' '+ passenger.lastName : "Unspecified"}</span>
            </div>
            <div className='flex gap-1'>
                <span className='text-gray-400 text-xs'>Email:</span>
                <span className='text-gray-400 text-xs'>{passenger.email!=="" && passenger.email!=="" ?  passenger.email : "Unspecified"}</span>
            </div>
        </div>
    })}
    </div>
    {passengerFormExpanded.sort((a, b) => a - b).map((passengerIndex, index) => {
            const passenger = passengers[passengerIndex]
            return <div key={index} className={'mb-6 flex gap-2 flex-col border border-gray-200 rounded-lg p-6'}>
                <h5 className="text-lg font-bold mb-2">Passenger {passengerIndex + 1} {passenger.type.charAt(0).toUpperCase() + passenger.type.slice(1)}</h5>
                <div className='flex flex-row gap-4'>
                    <div className="basis-1/2">
                        <FormLabelComponent error={passenger.firstNameError}>First Name</FormLabelComponent>
                        <FormInputComponent maxlength={20} error={passenger.firstNameError} placeholder={"First Name"} value={passenger.firstName} onChange={(val) => onChange(passengerIndex, 'firstName', val.target.value)} />
                        <FormInputError error={passenger.firstNameError} />
                    </div>
                    <div className="basis-1/2">
                        <FormLabelComponent error={passenger.lastNameError}>Last Name</FormLabelComponent>
                        <FormInputComponent maxlength={20} error={passenger.lastNameError} placeholder={"Last Name"} value={passenger.lastName} onChange={(val) => onChange(passengerIndex, 'lastName', val.target.value)} />
                        <FormInputError error={passenger.lastNameError} />
                    </div>
                </div>
                <div className='flex flex-row gap-4 flex-wrap sm:flex-nowrap'>
                    <div className="sm:basis-1/2 basis-full">
                        <FormLabelComponent error={passenger.genderError}>Gender</FormLabelComponent>
                        <FormSelectComponent error={passenger.genderError} options={genderOptions} selectedOption={passenger.gender} onChange={(val) => onChange(passengerIndex, 'gender', val.target.value)} />
                        <FormInputError error={passenger.genderError} />
                    </div>
                    <div className="sm:basis-1/2 basis-full">
                        <FormLabelComponent error={passenger.birthdayError}>Birthday</FormLabelComponent>
                        <FormDateComponent error={passenger.birthdayError} value={passenger.birthday} onChange={(val) => onChange(passengerIndex, 'birthday', val)} />
                        <FormInputError error={passenger.birthdayError} />
                    </div>
                </div>
                <div className='flex flex-row gap-4 flex-wrap sm:flex-nowrap'>
                    <div className="sm:basis-1/2 basis-full">
                        <FormLabelComponent error={passenger.phoneError}>Phone</FormLabelComponent>
                        <PhoneInput country={'us'}
                                placeholder={"Phone"}
                                value={passenger.phone}
                                onChange={(value, country, e, formattedValue) => onChange(passengerIndex, 'phone', formattedValue)}
                                isValid={passenger.phoneError == ""}
                                />
                        <FormInputError error={passenger.phoneError} />
                    </div>
                    <div className="sm:basis-1/2 basis-full">
                        <FormLabelComponent error={passenger.emailError}>Email</FormLabelComponent>
                        <FormInputComponent maxlength={50} type="email" error={passenger.emailError} placeholder={"Email"} value={passenger.email} onChange={(val) => onChange(passengerIndex, 'email', val.target.value)} />
                        <FormInputError error={passenger.emailError} />
                    </div>
                </div>
                <div className='flex flex-row gap-4 flex-wrap sm:flex-nowrap'>
                    <div className="sm:basis-1/2 basis-full">
                        <FormLabelComponent error={passenger.documentNumberError}>Passport number</FormLabelComponent>
                        <FormInputComponent maxlength={50} error={passenger.documentNumberError} placeholder={"Passport number"} value={passenger.documentNumber} onChange={(val) => onChange(passengerIndex, 'documentNumber', val.target.value)} />
                        <FormInputError error={passenger.documentNumberError} />
                    </div>
                    <div className="sm:basis-1/2 basis-full">
                        <FormLabelComponent error={passenger.documentExpiresOnError}>Passport Expiry date</FormLabelComponent>
                        <FormDateComponent error={passenger.documentExpiresOnError} value={passenger.documentExpiresOn} onChange={(val) => onChange(passengerIndex, 'documentExpiresOn', val)} />
                        <FormInputError error={passenger.documentExpiresOnError} />
                    </div>
                </div>
                <div className='flex flex-row gap-4 flex-wrap sm:flex-nowrap'>
                    <div className="sm:basis-1/2 basis-full">
                        <FormLabelComponent error={passenger.documentCountryError}>Passport Country</FormLabelComponent>
                        <FormCountrySelectorComponent error={passenger.documentCountryError} value={passenger.documentCountry} onChange={(val) => onChange(passengerIndex, 'documentCountry', val)}/>
                        <FormInputError error={passenger.documentCountryError} />
                    </div>
                    <div className="sm:basis-1/2 basis-full">
                    </div>
                </div>
            </div>
        })}
        <MainButton text="Book flight!" onClick={onSuccess} fullWidth={true}/>
    </>
}

export default PassengerForm