import { Link } from 'react-router-dom';
import Layout from '../Components/Layout/NoPlaneLayout';

const TripError = () => {
    return <Layout>
        <section class="bg-white">
            <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div class="mx-auto max-w-screen-sm text-center">
                    <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600">Trip Error</h1>
                    <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">There is a temporary issue.</p>
                    <p class="mb-4 text-lg font-light text-gray-500">Sorry, we can't your trip for now, please try again later </p>
                    <Link to="/" class="inline-flex bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">Back to Homepage</Link>
                </div>   
            </div>
        </section>
    </Layout>
}
export default TripError;