import React, { useEffect, useRef } from "react";
import airplane from './../../Images/airplane-min.webp'
import NavBar from '../NavBar';
import FooterMenu from "../FooterMenu";
const Layout = ({children}) => {

    const plane = useRef();

    useEffect(() => {
        plane.current.classList.add("moved")
    });

    return <>
    <NavBar/>
    <div className="mainbg hideOnDemand">{children}
    </div>
    <div className="plane-container"> 
        <img ref={plane} id="planeStuff" className="airplane" src={airplane} alt=""/>
    </div>
    <div className="clouds">
	    <div className="cloudsWrapper cloudImg">
        </div>
    </div></>
}

export default Layout;