const initialState = {
  id: 0,
  isLogged: false
};
const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {...state, ...action.payload};
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
export default currentUserReducer