import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSuitcase, faBagShopping } from '@fortawesome/free-solid-svg-icons'

const BaggageComponent = ({baggages}) => {
    const getTypeIcon = (type) => {
        switch(type) {
            case 'checked':
                return <FontAwesomeIcon icon={faSuitcase} />
                break;
            case 'carry_on':
                return <FontAwesomeIcon icon={faBagShopping} />;
                break;
        }
    }
    return <div className="flex w-full justify-around">{baggages.map((baggage, index) => {
        return <div key={index} className="mt-2 flex items-center gap-2">
            {getTypeIcon(baggage.type)} {baggage.quantity}
        </div>
    })}</div>
}

export default BaggageComponent;