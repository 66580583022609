import axios from 'axios';

export const userLogin = (user) => {
    return {
        type: 'LOGIN',
        payload: user
    }
}

export const updateBalance = (balance) => {
  return {
      type: 'LOGIN',
      payload: {
        balance: balance
      }
  }
}

export const userLogout = () => {
    return {
        type: 'LOGOUT'
    }
}

export const fetchUser = async () => {
    try { 
      const response = await axios.get(process.env.REACT_APP_SERVER_URL+'auth/user', {
        withCredentials:true,
        Headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true
        },   
      });
      const user = response.data;
      if(user){
        return {...user, isLogged: true};
      }
      
    } catch (err) {
     console.log(err);
    }

    return {
        id: 0,
        isLogged: false
      };
}
