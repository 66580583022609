import { useEffect, useState } from 'react';
import axios from 'axios'
import { useParams } from 'react-router-dom';
import ResultsLayout from '../Components/Layout/ResultsLayout';
import LogoComponent from '../Components/LogoComponent';
import MapBox from '../Components/MapBox'
import AccordionComponent from '../Components/AccordionComponent';
import LoaderComponent from '../Components/LoaderComponent';
import bookingLogo from '../Images/booking-logo.png'
import kayakLogo from '../Images/kayak.png'
import { formatDate } from '../Helpers/DateHelper'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { faWikipediaW, faGoogle} from '@fortawesome/free-brands-svg-icons'
import { useNavigate } from 'react-router-dom';
import WeatherForecast from '../Components/Weather/TripWeather'
import FlightsComponent from '../Components/Flights/FlightsComponent';
import MainButton from '../Components/MainButton';
import AppModal from '../Components/AppModal';
import OrdersComponent from '../Components/Flights/OrdersComponent';
import OrdersList from '../Components/Flights/OrdersList';
import RightPane from '../Components/RightPane/RightPane'
import { Modal } from '@mui/material';
import Preferences from "../Components/ActivityPreferences/Preferences";
import Button from '@mui/material-next/Button';
import SingleDayWeather from '../Components/Weather/SingleDayWeather';
import { kayakLinkFormat } from '../Helpers/StringHelper';
import { Link } from '@material-ui/core';


const Results = () => {
    const navigate = useNavigate()
    const { tripId } = useParams();
    const [ tripData, setTripData ] = useState(null);
    const [ tripPlan, setTripPlan ] = useState(null);
    const [ selectedDay, setSelectedDay ] = useState(0);
    const [ markers, setMarkers ] = useState([]);
    const [ isReady, setIsReady ] = useState(false);
    const [ tripPlanReady, setTripPlanReady ] = useState(false);
    const [ selectedPlace, setSelectedPlace ] = useState(null);
    const [ tripError, setTripError ] = useState(false);
    const [ placeImageGalleryModalShow, setPlaceImageGalleryModalShow ] = useState(false)
    const [ tripWeather, setTripWeather] = useState([]);
    const [ flightsModalShow, setFlightsModalShow ] = useState(false)
    const [ orders, setOrders] = useState([]);
    const [ showOrdersPopup, setShowOrdersPopup ] = useState(false)
    const [ isPrefModalOpen, setIsPrefModalOpen ] = useState(false);
    const [ activitiesPreferences, setActivitiesPreferences ] = useState({
        Spa:{ isMarked:false },
        Hiking:{ isMarked:false },
        Museums:{ isMarked:false },
        Skiing:{ isMarked:false },
        Diving:{ isMarked:false },
        Swimming:{ isMarked:false },
        Bicycle:{ isMarked:false },
        Shopping:{ isMarked:false },
        Animals:{ isMarked:false }
    })
    const [isBusy, setIsBusy] = useState(false)
    const prefModalAttirbutes = { isPrefModalOpen, setIsPrefModalOpen };
 
   const setData = (tripData) => {
       if(tripData != null && tripData.tripPlan ){
            try {
                const plan = (plan) => {
                    if(typeof plan == 'string') {
                        return JSON.parse(plan);
                    } else {
                        return plan;
                    }
                }

                const formattedTrip = formatTripPlan(plan(tripData.tripPlan))
                setTripPlan(plan(tripData.tripPlan));
                setMarkers(formattedTrip);
            } catch(e) {
                setTripError(true);
            }
       }
    }


    useEffect(() => {

        const initFadeOut = () => {
            document.getElementsByTagName('body')[0]?.classList.remove('makeHideOnDemand')
            
            const overlayElem = document.getElementsByClassName('general-overlay')[0]
            if(typeof overlayElem !== 'undefined') {
                overlayElem?.classList.remove('show')
                setTimeout(() => {
                    overlayElem.remove();
                },1500);
            }
        }

        const getWeatherByTripId = async () => {
            try {
                if(tripId){
                    const response = await axios.get(process.env.REACT_APP_SERVER_URL+'api/weather/'+tripId,{withCredentials:true})
                    if(response) {
                        //console.log(response)
                        setTripWeather(response.data);
                    }
                }
            } catch(err) {
                console.log(err);
            }
        }


        const fetchTripById = async () => {
            try {
                if(tripId){
                    const response = await axios.get(process.env.REACT_APP_SERVER_URL+'api/trip/'+tripId,{withCredentials:true})
                    if(response) {
                        const tripJson = response.data;
                        if(tripJson != "") {
                            if(tripJson.tripPlan !== "" && tripJson.tripPlan!==null) {
                                setTripPlanReady(true)
                            } else {
                                setTimeout(() => {
                                    fetchTripById()   
                                }, 5000)
                            }
                            setTripData(tripJson)
                            setData(tripJson)
                            setOrders(tripJson.orders)
                            setIsReady(true)
                            initFadeOut()
                        } else {
                            navigate("/404")
                        }
                    }
                }
            } catch(err) {
                navigate("/404")
                console.log(err);
            }
        }


        const fetchTripDate = async () => {
            await getWeatherByTripId();
            await fetchTripById();
        }
        
        fetchTripDate();

    }, [])

    useEffect(() => {
        setData(tripData)
        // eslint-disable-next-line
    }, [selectedDay])

    const PlaceImageComponent = (marker) => {
        setSelectedPlace(marker)
        let imgStyle = {backgroundImage: `url(${typeof marker.pics !== 'undefined' && marker.pics.length > 0 ? marker.pics[0]:""})`}
        return <>
            <div className='place-main-pic text-white flex flex-col justify-between shadow-[0px_-215px_62px_18px_inset_rgba(0,0,0,0.4)]' style={imgStyle}>
                {console.log(marker)}
                <h2 className='text-white text-xl'>{marker.name}</h2>
                <div>
                    <div className='text-xl flex gap-3'>
                        {typeof marker.pics !== 'undefined' && marker.pics.length > 0 ? <div className='cursor-pointer' onClick={() => setPlaceImageGalleryModalShow(true)}><FontAwesomeIcon icon={faCamera} /></div> : <></>}
                        <a className='cursor-pointer' target="_new" href={`http://en.wikipedia.org/w/index.php?title=Special:Search&search=${marker.name}`}><FontAwesomeIcon icon={faWikipediaW} /></a>
                        <a className='cursor-pointer' target="_new" href={"https://maps.google.com/?ll="+marker.latitude+","+marker.longitude}><FontAwesomeIcon icon={faGoogle} /></a>
                    </div>
                    <p className='text-white text-base'>{marker.place}</p>
                </div>
            </div>
        </>
    }

    const PlaceImageGallery = () => {
        const placePics = (selectedPlace?.pics)
        console.log("placepics",placePics);
        return <Lightbox open={placeImageGalleryModalShow} close={() => setPlaceImageGalleryModalShow(false)} 
        slides={placePics && Array.isArray(placePics) && placePics.map((str) => {
            return { src: str };
          })}
          >

        </Lightbox>
    }

    const PrefModal = () => {
        return (
            <Modal
            open={isPrefModalOpen}
            onClose={()=>setIsPrefModalOpen(false)}
            >
                <div className='prefModal'>
                    <Preferences activitiesPreferences={activitiesPreferences} setActivitiesPreferences={setActivitiesPreferences}/>
                    <Button  color="primary" variant="elevated" >
                        Edit Trip
                    </Button>
                </div>
            </Modal>
        )
    }

    const formatTripPlan = (tripPlan) => {
        if(tripPlan){

            const scheduleOfSelectedDay = tripPlan[selectedDay].schedule;
            //console.log("initial day object", scheduleOfSelectedDay)
            const events = [];
            for (const event in scheduleOfSelectedDay) {
                const { location, type, place, name = ""} = scheduleOfSelectedDay[event]
                const { lat, lng, pics } =  location
                const formatedEvent = {
                    location, type, place, latitude:lat, longitude:lng, name,pics
                }
                events.push(formatedEvent);
            }
            return events;
        }
    }

    const tripDayHeaderComponent = (item) => {
        return <div className='flex flex-row items-center w-full justify-between gap-1'>
            <div className='flex flex-col gap-1'>
                <div>Day {item.key + 1}</div>
                <div className='text-xs	'>{formatDate(item.title)}</div>
            </div>
            {tripWeather.filter(it => it != null && it.date != null && it.date == item.title).map((item, index) => {
                return <SingleDayWeather key={index} dailyWeather={item}/>
            })}
        </div>
    }

    const tripDayContentComponent = (item) => {
        try {
        const tripItem = tripPlan[item.key]
        return <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500">
                <tbody>
                    {Object.keys(tripItem.schedule).map((it, index) => {
                        return <tr key={index} className={index % 2 === 0 ? "bg-white border-b" : "border-b bg-gray-50"}>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                {it }
                            </th>
                            <td className="px-6 py-4">
                                <p className="text-sm text-gray-900">{tripItem.schedule[it].place}</p>
                                {/* <a href={"https://maps.google.com/?ll="+tripItem.schedule[it].location.latitude+","+tripItem.schedule[it].location.longitude} className="text-xs text-gray-60" target="_new">{tripItem.schedule[it].location}</a> */}
                            </td>
                            <td className="px-6 py-4">
                            {tripItem.schedule[it].type}
                            </td>
                            <td className="px-6 py-4">
                                {tripItem.schedule[it].price}
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        } catch(e) {
            console.log(e)
            return <></>
        }
    }

    const restartPlanning = async () => {
        if(!tripId) return; 
        if(isBusy) return;

        setIsBusy(true)
        
        const response = await axios.get(process.env.REACT_APP_SERVER_URL+'api/trip/retryBuildPlan/'+tripId,{withCredentials:true})

        window.location.reload();
    }

    if(!isReady) return <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden opacity-75 flex flex-col items-center justify-center">
	    <LoaderComponent />
    </div>

    return <>
    <ResultsLayout>
    <div className='flex lg:flex-row flex-col-reverse h-screen overflow-y-hidden overflow-x-hidden'>
        <section className='body lg:w-[55%] h-[calc(100vh-17rem)]  bg-tp-bg-color relative w-full lg:h-screen flex flex-col'>
            <div className='lg:px-24 lg:py-5 md:px-8 md:py-16 px-5 py-5'>
                <LogoComponent black={true} />
            </div>
            <div className='lg:px-24 lg:py-5 md:px-8 md:py-16 px-5 py-5 overflow-y-auto'>
                <h2 className="text-4xl font-extrabold">{tripData.destination}</h2>
                {/*<div className='mt-6'>
                    <MainButton onClick={() => setFlightsModalShow(true)} text='Buy your flight now' />
                </div>*/}
                <div className='flex gap-4 mt-6'>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <p style={{fontWeight:'bolder'}}>Book Your Stay In Advance</p>
                        <img id='bookingRedirect' height={100} width={100} className="cursor-pointer hover:cursor-pointer"
                            onClick={() => window.open(process.env.REACT_APP_BOOKING_AFFILIATE_LINK+`&ss=${tripData.destination}&checkin=${tripData.departureDate}&checkout=${tripData.returnDate}`,'_blank')} src={bookingLogo} alt=""/>
                    </div>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <p style={{fontWeight:'bolder'}}>Find cheap flights to {tripData.destination}</p>
                        <div className='flex items-center h-full'>
                            <img id='kayakRedirect' style={{height:'auto',width:'100px'}} className="cursor-pointer hover:cursor-pointer"
                                onClick={() => {
                                    const link = kayakLinkFormat(tripData, process.env.REACT_APP_KAYAK_AFFILIATE_LINK)
                                    window.open(link,'_blank')
                                }} src={kayakLogo} alt=""/>
                        </div>
                    </div>
                </div>
                
                <AccordionComponent items={tripPlan?.map((item, index) => {return { title: item.date, key: index }})}
                renderHeader={tripDayHeaderComponent}
                onItemSelected={setSelectedDay}
                renderItem={tripDayContentComponent} />

            </div>
        </section>
        <div className="rightConatiner">
            <div className='flexCol' style={{width:"100%"}}>
                {tripWeather && 
                        <WeatherForecast tripWeather={tripWeather}/>}
                <div className='mapbox lg:h-full h-[17rem] lg:w-[85%] w-full block flex items-center justify-center'>
                {tripError ? <div>
                    <p className='text-orange-700 font-bold text-center'>There was an error loading your trip</p>
                    <p className='text-center'><Link onClick={() => restartPlanning()}>Click here</Link> to retry</p>
                </div> : <></>}
                {!tripPlanReady && !tripError? 
                    <LoaderComponent showLabels={true} /> : 
                    <MapBox placeImageCompoent={PlaceImageComponent} markers={markers}/>}
                    {/*<div className='min-w-[60px] text-center'>
                    <OrdersComponent onClick={() => setShowOrdersPopup(true)} orders={orders} />
                </div>*/}
                </div>
            </div>
        </div>
       {/* <RightPane prefModalAttirbutes={prefModalAttirbutes}/> */}
    </div>
    <AppModal onClose={() => setShowOrdersPopup(false)} showModal={showOrdersPopup} title={"Your orders"}>
        <OrdersList orders={orders} />
    </AppModal>
    <AppModal onClose={() => { setFlightsModalShow(false) }} showModal={flightsModalShow} title={"Book your flight now with us"}>
        <FlightsComponent tripId={tripData.id} setOrders={setOrders} />
    </AppModal>
    </ResultsLayout>
    <PlaceImageGallery />
    <PrefModal/>
    
    </>
}

export default Results