import FormLabelComponent from "./FormLabelComponent";
import FormSelectComponent from "./FormSelectComponent";

const FamilySelector = ({
    adults = 0,
    setAdults = (_) => {},
    kids = 0,
    setKids = (_) => {},
    infants = 0,
    setInfants = (_) => {}
}) => {

    const getOpts = (isAdult = false) => {
        let arr = [];
        for(let i = 0; i <= 5; i++) {
            if(i == 0 && isAdult) continue;
            arr.push(
                {label: i, value: i}
            )
        }

        return arr
    }

    return <div className="flex w-full gap-4 mb-6">
        <div className="flex flex-col flex-1">
            <FormLabelComponent>Adults:</FormLabelComponent>
            <FormSelectComponent selectedOption={adults} options={getOpts(true)} onChange={(event) => setAdults(event.target.value)} />
        </div>
        <div className="flex flex-col flex-1">
            <FormLabelComponent>Kids:</FormLabelComponent>
            <FormSelectComponent selectedOption={kids} options={getOpts()} onChange={(event) => setKids(event.target.value)} />
        </div>
        <div className="flex flex-col flex-1">
            <FormLabelComponent>Infants:</FormLabelComponent>
            <FormSelectComponent selectedOption={infants} options={getOpts()} onChange={(event) => setInfants(event.target.value)} />
        </div>
    </div>
}

export default FamilySelector;