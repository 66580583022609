import React from 'react'
import styles from "./ActivityPreferences.module.css"
import SingleActivity from './SingleActivity';
import HikingIcon from '@mui/icons-material/Hiking';
import PetsIcon from '@mui/icons-material/Pets';
import SpaIcon from '@mui/icons-material/Spa';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import MuseumIcon from '@mui/icons-material/Museum';
import ScubaDivingIcon from '@mui/icons-material/ScubaDiving';
import PoolIcon from '@mui/icons-material/Pool';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';

const BATCH_SIZE = 3;
const Preferences = ({activitiesPreferences, setActivitiesPreferences}) => {
    const preferencesAttributes = { activitiesPreferences, setActivitiesPreferences };

    const preferencesMapping = [
        {activityName:'Spa', icon:<SpaIcon/>},
        {activityName:'Museums', icon:<MuseumIcon/>},
        {activityName:'Skiing', icon:<DownhillSkiingIcon/>},
        {activityName:'Diving', icon:<ScubaDivingIcon/>},
        {activityName:'Swimming', icon:<PoolIcon/>},
        {activityName:'Hiking', icon:<HikingIcon/>},
        {activityName:'Bicycle', icon:<DirectionsBikeIcon/>},
        {activityName:'Shopping', icon:<LocalOfferIcon/>},
        {activityName:'Animals', icon:<PetsIcon/>},
    ];

    const generateRows = () => {
        try{
            const activityBatches = [];
            for (let batchIndex = 0; batchIndex < preferencesMapping.length/BATCH_SIZE; batchIndex++) {
    
            const currentBatch = [];
            for (let activityIndex = batchIndex * BATCH_SIZE; activityIndex < preferencesMapping.length && activityIndex <  (batchIndex + 1) * (BATCH_SIZE); activityIndex++) {

                const currActivity = preferencesMapping[activityIndex];

                if(currActivity){
                    const { activityName, icon } = currActivity
                    currentBatch.push({ activityName, icon });
                }
            }           

            activityBatches.push(currentBatch);

        }
            return activityBatches.map((batch, batchIndex)=> 
                <div key={batchIndex} className={styles.rowPreferencesContainer}>
                    {batch.map((currActivity)=>
                        <SingleActivity key={currActivity.activityName} preferencesAttributes = { preferencesAttributes } activityName={currActivity.activityName} icon={currActivity.icon}/>
                    )}
                </div>
            )
        } catch(err){
            console.log(err);
            return <></>
        }
    }
    
    return(
        <div className={styles.preferencesContainer}>
              {generateRows()}
        </div>
      
        )

}

export default Preferences;