const TripMainActivitySelector = ({
    tripMainActivity = "",
    setTripMainActivity = (_) => {}
}) => {

    return <>
    <h2 className="mb-4">Select your main activity</h2>
    <div className="flex w-full gap-4 flex-wrap justify-center mb-4">
        <div onClick={() => setTripMainActivity("Skiing")} className="cursor-pointer flex items-end type-selector rounded-lg flex-1 p-4 text-white font-bold type-activity-1 min-w-[175px] max-w-[200px]">
            <p className="drop-shadow-lg">Ski</p>
        </div>
        <div onClick={() => setTripMainActivity("Beaching")} className="cursor-pointer flex items-end type-selector rounded-lg flex-1 type-activity-2 p-4 text-white font-bold min-w-[175px] max-w-[200px]">
        <p className="drop-shadow-lg">Beaching</p>
        </div>
        <div onClick={() => setTripMainActivity("Urban Exploring")} className="cursor-pointer flex items-end type-selector rounded-lg flex-1 type-activity-3 p-4 text-white font-bold min-w-[175px] max-w-[200px]">
        <p className="drop-shadow-lg">Urban Exploring</p>
        </div>
    </div></>
}

export default TripMainActivitySelector;