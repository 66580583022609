
import { useState } from 'react';

const AccordionComponent = ({
  id = "",
  items = [], 
  renderHeader = (item) => { return <span>{item.title}</span> },
  renderItem = (item) => {},
  onItemSelected = (item) => {}
}) => {

  const [ activeIcon, setActiveIcon ] = useState(0)

  const onAccordionClick = (index) => {
    setActiveIcon(index == activeIcon ? -1 : index)
    onItemSelected(index)
  }

  const getHeader = () => {
    return items.map((item, index) => {
        return <div key={index}>
        <h2 onClick={() => {onAccordionClick(index)}} id={"accordion-flush-heading-"+index}>
          <button type="button" className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200" data-accordion-target="#accordion-flush-body-1" aria-expanded="true" aria-controls="accordion-flush-body-1">
            {renderHeader(item)}
            {activeIcon === index ?
            <svg className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg> :
            <svg data-accordion-icon className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            }
          </button>
        </h2>
        <div id={"accordion-flush-body-"+index} className={activeIcon === index ? "" : "hidden"} aria-labelledby="accordion-flush-heading-1">
          {renderItem(item)}
        </div>
        </div>
    })
  }

    return <>{getHeader()}</>
}

export default AccordionComponent;