import { Link } from "react-router-dom";


const FooterMenu = () => {
    return (
        <div className="flex gap-4 mt-4 mb-4">
            <Link className="text-sm" to="/terms">Terms and conditions</Link>
            <Link className="text-sm" to="/privacy">Privacy policy</Link>
        </div>
    )
}

export default FooterMenu;