import { useState, useCallback, useRef } from "react";
import axios from "axios";
import FormLabelComponent from "./FormLabelComponent";
import FormInputComponent from "./FormInputComponent";
import FormInputError from "./FormInputError";
import { makeid, createSessionToken } from "../Helpers/StringHelper";
import debounce from 'lodash.debounce';
import useComponentVisible from "../Helpers/ComponentHelper"
import LoaderComponent from "./LoaderComponent";

const FoursquarePlaceAutoCompleteComponent = ({
    label,
    error,
    placeholder,
    onChange = () => {},
    value
}) => {
    const [sessionToken, setSessionToken] = useState(createSessionToken())
    const [places, setPlaces] = useState([])
    const [loading, setIsLoading] = useState(false)
    const { ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);

    const refreshSessionToken = () => {
        setSessionToken(createSessionToken())
    }

    const inputChange = (event) => {
        onChange({
            formatted_address: event.target.value,
            place_id: '',
            lat: 0,
            lng: 0,
            cc: ''
        });
        debouncedChangeHandler(event)
    }

    const getPredictions = (event) => {
        setIsLoading(true)
        const response = axios.get(`https://api.foursquare.com/v3/autocomplete?query=${event.target.value}&types=geo&bias=geo&session_token=${sessionToken}`,
        {
            headers: {
                'accept': 'application/json',
                'Authorization': process.env.REACT_APP_FOURSQAURE_API_KEY || ''
            }
        }).then((response) => {
            var response = response.data;
            const places = response.results.map((it) => it.geo).filter(item => item.type=='locality')

            setPlaces(places)
            setIsLoading(false)
        },(error) => {
            console.log(error)
            setIsLoading(false)
        });
    }

    const debouncedChangeHandler = useCallback(
        debounce(getPredictions, 300)
    , []);


    const renderPlaceItem = (item, index) => {
        return <div className="text-sm p-2 hover:bg-slate-50 cursor-pointer" onClick={() => {
            setPlaces([])
            setIsComponentVisible(false)
            onChange({
                formatted_address: item.name,
                place_id: '',
                lat: item.center.latitude,
                lng: item.center.longitude,
                cc: item.cc
            });
        }} key={index}>{item.name}</div>
    }


    const predictionsResult = () => {
        if(!isComponentVisible || (!loading && places.length < 1)) return <></>
        return <div className="absolute z-10 w-full drop-shadow-lg bg-white py-2">
            {loading ? <LoaderComponent /> : <div className="grid grid-cols-1 divide-y">{places.map(renderPlaceItem)}</div>}
        </div>
    }

    return <div className="block relative" ref={ref}>
        <FormLabelComponent error={error}>{label}</FormLabelComponent>
        <FormInputComponent error={error} placeholder={placeholder} value={value.formatted_address} onClick={refreshSessionToken} onChange={inputChange} />
        {predictionsResult()}
        <FormInputError error={error} />
    </div>
}

export default FoursquarePlaceAutoCompleteComponent;