import React, { useEffect, useRef } from "react";
import NavBar from '../NavBar';
const NoPlaneLayout = ({children}) => {

    return <>
    <NavBar/>
    <div className="bg-white">
    <div className="">{children}</div>
    </div>
    </>
}

export default NoPlaneLayout;