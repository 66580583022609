import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import DateRangePickerComponent from "./DateRangePickerComponent";
import MainButton from "./MainButton";
import Destination from "./Destination";
import StepsIndicatorComponent from "./StepsIndicatorComponent";
import SignUpComponent from "./SignUpComponent";
import AdditionalDetailsForm from "./AdditionalDetailsForm";
import { Link } from "react-router-dom";
import { daysFromDates } from "../Helpers/DateHelper";
import FormInputError from "./FormInputError"
import { fetchUser, userLogin } from "../redux/actions/currentUserAction";
import { useSelector, useDispatch } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import LoaderComponent from "./LoaderComponent";
import FooterMenu from "./FooterMenu";
import Preferences from "./ActivityPreferences/Preferences";
import TripTypeSelector from "./TripTypeSelector";
import FamilySelector from "./FamilySelector";
import TripMainActivitySelector from "./TripMainActivitySelector";
import TripDestinationSuggestionSelector from "./TripDestinationSuggestionSelector";
import AppModal from "./AppModal";
import FormLabelComponent from "./FormLabelComponent";
import { useSearchParams } from 'react-router-dom'
import PaymentPopup from "./Payment/PaymentPopup";

const Form = () => {

    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((store) => store.currentUser);

    const [tripType, setTripType] = useState(0);
    const [tripMainActivity, setTripMainActivity] = useState("");

    const [tripDestinationLoading, setTripDestinationLoading] = useState(true)
    const [tripDestinationSelection, setTripDestinationSelection] = useState([]);
    const [tripDestination, setTripDestination] = useState(null);
    const [tripActivitiesModalOpen, setTripActivitiesModalOpen] = useState(false);
    const [ isAuthLoading, setIsAuthLoading ] = useState(false);

    const [adults, setAdults] = useState(0);
    const [kids, setKids] = useState(0);
    const [infants, setInfants] = useState(0);

    const [ useBudget, setUseBudget ] = useState(0)
    const [ budget, setBudget ] = useState(0.0)

    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
        key: 'selection'
    });
    const [ dateRangeError, setDateRangeError ] = useState('');

    const [ destination, setDestination ] = useState({
        formatted_address: '',
        place_id: '',
        lat: 0,
        lng: 0, 
        cc: ''
    });
    const [ destinationError, setDestinationError ] = useState('');

    const [ departure, setDeparture ] = useState({
        formatted_address: '',
        place_id: '',
        lat: 0,
        lng: 0,
        cc: ''
    });
    const [ departureError, setDepartureError ] = useState('');

    const [ additionalDetailsFormData, setAdditionalDetailsFormData ] = useState({});

    const [ activitiesPreferences, setActivitiesPreferences ] = useState({
        Spa:{ isMarked:false },
        Hiking:{ isMarked:false },
        Museums:{ isMarked:false },
        Skiing:{ isMarked:false },
        Diving:{ isMarked:false },
        Swimming:{ isMarked:false },
        Bicycle:{ isMarked:false },
        Shopping:{ isMarked:false, },
        Animals:{ isMarked:false, }
    }
    );

    const [travelers, setTravelers] = useState('solo')
    const [travelersError, setTravelersError] = useState('')

    const [foodRestrictions, setFoodRestrictions] = useState('None')
    const [foodOptionsError, setFoodOptionsError] = useState('')

    const [ activityDesnisty, setActivityDesnisty ] = useState(3)

    const [ transportation, setTransportation ] = useState('all');
    const [ transportationError, setTransportationError ] = useState('');

    const [ currency, setCurrency ] = useState('EUR');

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")

    const [showPaymentPopup, setShowPaymentPopup] = useState(false)

    const prepareAdditionalDetailsProps = () => {
        return {
            travelers,
            setTravelers,
            travelersError,
            foodRestrictions,
            setFoodRestrictions,
            foodOptionsError,
            activityDesnisty,
            setActivityDesnisty,
            transportation,
            setTransportation,
            transportationError,
            useBudget,
            setUseBudget,
            budget,
            setBudget,
            currency,
            setCurrency
        }
    }

    const [ formStep, setFormStep ] = useState(0);
    
    const launchPlane = (response) => {
        try {
            document.getElementsByTagName('body')[0].classList.add('makeHideOnDemand')
            const overlayElem = document.createElement('div');
            overlayElem.classList.add("general-overlay")
            document.getElementsByTagName('body')[0].appendChild(overlayElem)
            setTimeout(() => {
                document.getElementById("planeStuff").classList.add("shake")
                setTimeout(() => {
                    document.getElementById("planeStuff").classList.remove("shake")
                    document.getElementById("planeStuff").classList.add("full")
                    setTimeout(() => {
                        document.getElementById("planeStuff").classList.add("hidden")
                        document.getElementById("planeStuff").classList.remove("moved")
                        document.getElementById("planeStuff").classList.remove("full")
                        overlayElem.classList.add('show');
                        setTimeout(() => {
                            document.getElementById("planeStuff").classList.remove("hidden")
                            setTimeout(() => {
                                document.getElementById("planeStuff").classList.add("moved")
                                navigate("/results/"+response.id)
                            }, 500)
                        }, 1500)
                    }, 1000)
                }, 500)
            }, 500)
        } catch(e) {
            navigate("/results/"+response.id)
        }
    }


    const getAuth = async (provider) => {
        setIsAuthLoading(true)
        try{
          const win = window.open(process.env.REACT_APP_SERVER_URL + 'auth/login/'+provider,'targetWindow',
          `toolbar=no,
           location=no,
           status=no,
           menubar=no,
           scrollbars=yes,
           resizable=yes,
           width=450,
           height=600`);
  
           var timer = setInterval(async function() { 
              if(win.closed) {
                  clearInterval(timer);
                  await onLoggedIn()
              }
          }, 500);
  
        } catch(err) {
          console.log(err)
          setIsAuthLoading(false)
        }
      }

    const onSubmitHandle = async (step) => {
        console.log("step Number", step);
        if(step === 2 ) {
            if( user.isLogged ){
                createTrip()
            } else {
                getAuth("google");
            }
            console.log("creating trip");
        }
        setFormStep(step + 1);
    }

    const titleElem = useRef();
    const subTitleElem = useRef();
    const formElem = useRef();

    useEffect(() => {
        titleElem.current.classList.add("opacity-100")
        formElem.current.classList.add("opacity-100")
        subTitleElem.current.classList.add("opacity-100")
    });

    useEffect(() => {
        if(formStep === 2 && tripDestination){
            onSubmitHandle(formStep);
        }
    },[formStep, tripDestination])

    const fetchPlaceSuggestions = async(activity) => {
        try {
            setTripDestinationLoading(true)
            setTripDestinationSelection([])
            const response = await axios.post(process.env.REACT_APP_SERVER_URL + `api/destinations/${activity}/${(new Date(dateRange.startDate).toLocaleString('en-US', { month: 'long' }))}`, {}, {
                withCredentials:true
            });
            if(!response.data) {
                console.log(response)
                throw "Invalid resp"
            }
            setTripDestinationSelection(response.data)
            setTripDestinationLoading(false)
        } catch(e) {
            console.log(e)
            setTripDestinationLoading(false)
        }
    }

    const onLoggedIn = async (profile) => {
        setError("")
        const user = await fetchUser();
        dispatch(userLogin(user));
        if(user.isLogged) {
            setFormStep(tripType == 0 ? 2 : 3)
        } else {
            setError("Could not complete login, please try again")
        }
        setIsLoading(false)
    }

    const createTrip = async (openModal = false) => {
        //Axios bla bla
        setError("")
        setIsLoading(true)
        console.log("destination params", tripDestination);
        const params = {
            destination: tripDestination.city+', '+tripDestination.name,
            destinationPlaceId: "",
            destinationLat: tripDestination.lat,
            destinationLng: tripDestination.lng,
            destinationCC: tripDestination.countryCode,
            type: tripType,
            foodRestrictions,
            travelWith: travelers,
            currency,
            budget: budget.floatValue,
            departureDate: (new Date(dateRange.startDate)).toISOString().split('T')[0],
            departureLocation: departure.formatted_address,
            departurePlaceId: departure.place_id,
            departureLat: departure.lat,
            departureLng: departure.lng,
            departureCC: departure.cc,
            returnDate: (new Date(dateRange.endDate)).toISOString().split('T')[0],
            activitiesPreferences,
            showPayment: searchParams.get('showPayment')??""
        };
        try {
            console.log("trip params", params);
            const response = await axios.post(process.env.REACT_APP_SERVER_URL + 'api/trip/create/', params, {
                withCredentials:true
            });
            if(!response.data) {
                console.log(response)
                throw "Invalid resp"
            }
            if(response.data.status) {
                if(openModal) {
                    window.open("/results/"+response.data.trip.id, `_trip${response.data.trip.id}`)
                    setIsLoading(false)
                    setTripActivitiesModalOpen(false)
                } else {
                    launchPlane(response.data.trip)
                }
            } else {
                setShowPaymentPopup(true)
            }
        } catch(e) {
            console.log(e);
            setError("Could not complete operation, please try again later")
            setIsLoading(false)
        }
    }

    const validateFirstForm = () => {
        setDateRangeError("")
        setDestinationError("")
        setDepartureError("")
        let isValid = true
        if(dateRange.startDate === null || dateRange.endDate === null) {
            setDateRangeError("Please select dates")
            isValid = false
        }

        if((new Date(dateRange.startDate)).setHours(0,0,0,0) < (new Date()).setHours(0,0,0,0)) {
            setDateRangeError("Please select a future date")
            isValid = false
        }

        if((new Date(dateRange.startDate)).setHours(0,0,0,0) >= (new Date(dateRange.endDate)).setHours(0,0,0,0)) {
            setDateRangeError("Start date must be later than end date")
            isValid = false
        }

        if(daysFromDates(new Date(dateRange.endDate), new Date(dateRange.startDate)) > 10) {
            setDateRangeError("Maximum of 10 days")
            isValid = false
        }
        
        if(tripType == 1 && destination.lat === 0 && destination.lng === 0) {
            setDestinationError("Please select from the list and dont edit")
            isValid = false
        }

        if(departure.formatted_address === "") {
            setDepartureError("Please select from the dropdown")
            isValid = false
        }

        return isValid
    }

    const getCurrentStep = () => {
        console.log(formStep);
        if(formStep === 0) 
            return (
                <>
                    <Destination tripType={tripType} destination={destination} destinationError={destinationError} setDestination={setDestination} departure={departure} departureError={departureError} setDeparture={setDeparture} />
                    <DateRangePickerComponent dateRange={dateRange} error={dateRangeError} setDateRange={setDateRange} />
                    <FamilySelector adults={adults} setAdults={setAdults} kids={kids} setKids={setKids} infants={infants} setInfants={setInfants} />
                    <div className="text-center flex justify-center flex-col">
                        <MainButton onClick={() => {
                            if(validateFirstForm())
                                setFormStep(formStep + 1);
                        }}/>
                    </div>
                </>
            )
        
        if(formStep === 1) 
            return (
                <>
                    <TripMainActivitySelector tripMainActivity={tripMainActivity} setTripMainActivity={(val) => {setTripMainActivity(val); onSubmitHandle(formStep); fetchPlaceSuggestions(val)}} />
                    <div className="text-center flex justify-center flex-col">
                        <Link onClick={() => {setFormStep(formStep -1)}}>Back</Link>
                    </div>
                </>
            )
        
        // if(formStep === 2) return user.isLogged ? <>
        //     {isLoading ? <div className="text-center"><LoaderComponent /></div> : <></>}
        //     </> : <>
        //         <SignUpComponent onSuccess={onLoggedIn} isLoading={isLoading} setIsLoading={setIsLoading} error={error} setError={setError}/>
        //         {!isLoading ? <div className="text-center flex justify-center flex-col">
        //             <Link onClick={() => {setFormStep(formStep -1)}}>Back</Link>
        //         </div> : <></>}
        // </>

        // if(formStep === 3) return <>
        //     <TripMainActivitySelector tripMainActivity={tripMainActivity} setTripMainActivity={(val) => {setTripMainActivity(val); setFormStep(3)}} />
        //     <div className="text-center flex justify-center flex-col">
        //         <Link onClick={() => {setFormStep(formStep -2)}}>Back</Link>
        //     </div>
        // </>

        if(formStep === 2) return <>
            <TripDestinationSuggestionSelector isLoading={tripDestinationLoading} tripDestinationSelection={tripDestinationSelection} 
            setTripDestination={(val) => {setTripDestination(val)}} />
            {!tripDestinationLoading ? <div className="text-center flex justify-center flex-col">
                <Link onClick={() => {setFormStep(formStep -1)}}>Back</Link>
            </div> : <></>}
        </>

        // if(formStep === 3) return <>
        //     <AdditionalDetailsForm {...prepareAdditionalDetailsProps()} />
        //         <div className="text-center flex justify-center flex-col">
        //             <MainButton onClick={() => onSubmitHandle(formStep, tripType)}/>
        //             <Link onClick={() => {setFormStep(tripType == 1 ? formStep -2 : formStep -1)}}>Back</Link>
        //         </div>
        //     </>
        // if(formStep === 4) return <>
        //     <Preferences activitiesPreferences={activitiesPreferences} setActivitiesPreferences={setActivitiesPreferences}/>
        //     <div className="text-center flex justify-center flex-col">
        //         <MainButton onClick={() => onSubmitHandle(formStep, tripType)}/>
        //         <Link onClick={() => {setFormStep(tripType == 0 ? formStep -2 : formStep -1)}}>Back</Link>
        //     </div>
        // </>
        if(formStep === 3) return <></>
    }
    
    return (
        <>
            <div className='mt-6 flex flex-col container mx-auto items-center justify-center inset-x-0 absolute z-10'>
                <h1 ref={titleElem} className="text-white font-semibold text-center md:text-7xl opacity-0 mb-2 text-5xl">Travel smart with us</h1>
                <h2 ref={subTitleElem} className="text-white text-3xl mb-6 opacity-0">AI Customized Trips</h2>
                <div ref={formElem} className='w-full max-w-2xl transition transition-opacity duration-[1500ms]  opacity-0'>
                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        {getCurrentStep()}
                        <FormInputError error={error} />
                        {/*<StepsIndicatorComponent step={formStep} />*/}
                    </div>
                </div>
                <FooterMenu />
            </div>
            {/* <AppModal size="max-w-lg" showModal={tripActivitiesModalOpen} onClose={() => setTripActivitiesModalOpen(false)} title="Additional details">
                <FormLabelComponent >Additional Activities</FormLabelComponent>
                <Preferences activitiesPreferences={activitiesPreferences} setActivitiesPreferences={setActivitiesPreferences}/>
                <AdditionalDetailsForm {...prepareAdditionalDetailsProps()} />
                <div className="flex items-center justify-center">
                 {isLoading ? <LoaderComponent /> : <MainButton text="Show trip" onClick={() => createTrip(true)}/>}
                </div>
                <FormInputError error={error} />
            </AppModal> */}
            <PaymentPopup show={showPaymentPopup} onClose={() => {
                setShowPaymentPopup(false)
                setTripDestination(null)
                setTripMainActivity("")
                setFormStep(1)
            }} onCompleted={(user) => {
                dispatch(userLogin(user));
                createTrip()
            }}/>
        </>
)
}

export default Form;