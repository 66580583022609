
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlane } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import MainButton from '../MainButton'

const NumberOfTravelers = ({
    onSelect = (types) => {},
    onBack = () => {}
}) => {

    const [adults, setAdults] = useState(1)
    const [childs, setChilds] = useState(0)
    
    return <div className='justify-center items-center text-center'>
    <h6 className='text-md gap-2 items-center mb-3 text-center'>
    Please confirm the number of travelers
    </h6>
    <div className='justify-center text-center'>Adults</div>
    <div className='flex gap-2 justify-center'>
    {[1,2,3,4,5].map((number, index) => {
        return <div key={index} onClick={() => setAdults(number)} className={"cursor-pointer p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100" + (adults == number ? " border-green-600" : "")}>
        <h5 className="text-2xl font-bold text-gray-900">{number}</h5>
        </div>
    })}
    </div>

    <div className='mt-4 text-center'>Children</div>
    <div className='flex gap-2 mb-5 justify-center'>
    {[0,1,2,3,4].map((number, index) => {
        return <div key={index} onClick={() => setChilds(number)} className={"cursor-pointer p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100" + (childs == number ? " border-green-600" : "")}>
        <h5 className="text-2xl font-bold text-gray-900">{number}</h5>
        </div>
    })}
    </div>
    <MainButton text="Continue" onClick={() => {
        let types = []
        for(let i = 1; i<=adults; i++) {
            types.push({type: "adult"})
        }
        for(let i = 1; i<=childs; i++) {
            types.push({type: "child"})
        }

        onSelect(types)
    }} />
    </div>
}

export default NumberOfTravelers