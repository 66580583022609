import { forwardRef } from 'react';

const FormInputComponent = forwardRef(({
    readonly,
    placeholder,
    onClick = () => {},
    type = "text",
    onChange,
    id,
    value,
    error = "",
    maxlength = 255
}, ref) => {
    const getClasses = () => {
        if(error !== "")
            return "bg-gray-50 border border-red-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        else
            return "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    }
    return <input ref={ref} maxLength={maxlength} onChange={onChange} onClick={() => {onClick()}} type={type} id={id} value={value} className={getClasses()} placeholder={placeholder} readOnly={readonly} />
})

export default FormInputComponent;