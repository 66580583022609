import { formatCurrency } from "../../Helpers/StringHelper";
import AppModal from "../AppModal"
import axios from "axios";
import Cards from 'react-credit-cards-2';
import React, { useEffect, useState } from 'react';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    formatFormData
  } from "../../Helpers/CardHelper";
import MainButton from "../MainButton";
import LoaderComponent from "../LoaderComponent";
import FormInputError from "../FormInputError";

const PaymentPopup = ({
    show,
    onClose,
    onCompleted
}) => {

    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
      window.dataLayer.push({
        'event': 'paymentModalOpened'
      });
    })

    const [state, setState] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
      });

    const submit = async () => {
        setError("")
        setIsLoading(true)
        try {
            window.dataLayer.push({
              'event': 'paymentButtonClicked'
            });
            const resp = await axios.post(process.env.REACT_APP_SERVER_URL + `api/payments/pay`, {
                ccNumber: state.number,
                ccDate: state.expiry.replace('/', ''),
                ccCvv: state.cvc,
                ccName: state.name
            }, {
                withCredentials:true
            });

            if(resp.data.status) {
                window.dataLayer.push({
                  'event': 'paymentCompleted'
                });
                onCompleted(resp.data.user)
            } else {
                window.dataLayer.push({
                  'event': 'paymentFailed'
                });
                setIsLoading(false)
                setError(resp.data.message)
            }
        } catch(e) {
            window.dataLayer.push({
              'event': 'paymentFailed'
            });
            setIsLoading(false)
            setError("Could not complete operation, please try again later")
        }
    }

      const handleInputChange = ({ target }) => {
        if (target.name === "number") {
          target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
          target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
          target.value = formatCVC(target.value);
        }
        setState((prev) => ({ ...prev, [target.name]: target.value }));
      };
    
      const handleInputFocus = (evt) => {
        setState((prev) => ({ ...prev, focus: evt.target.name }));
      }

    return <AppModal size="max-w-lg" showModal={show} onClose={() => onClose()} title="Trial expired">
        You free trial has expired! <br />
        for only <b>{formatCurrency(3, "USD")}</b> get free access for a month
        <div className="mt-4">
      <Cards
        number={state.number}
        expiry={state.expiry}
        cvc={state.cvc}
        name={state.name}
        focused={state.focus}
      />
      {isLoading ? <LoaderComponent /> : <form onSubmit={() => submit()}>
            <div className="flex flex-col gap-3 mt-4">
                <input
                    type="tel"
                    name="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Card Number"
                    pattern="[0-9\s]{13,19}"
                    required
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                />
              <input
                type="text"
                name="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Name"
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
            </div>
            <div className="flex flex-row gap-3 mt-4">
                <input
                  type="tel"
                  name="expiry"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Valid Thru"
                  pattern="\d\d/\d\d"
                  required
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
                <input
                  type="tel"
                  name="cvc"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="CVC"
                  pattern="\d{3,4}"
                  required
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
            </div>
            <div className="flex w-full mt-4">
                <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full' type="submit">{"Pay " + formatCurrency(3, "USD")}</button>
            </div>
      </form>}
      <FormInputError error={error} />
    </div>
    </AppModal>
}

export default PaymentPopup