import { CardPayment } from '@duffel/components'
import '@duffel/components/dist/CardPayment.min.css'
import { formatCurrency } from '../../Helpers/StringHelper';
import { Link } from '@material-ui/core';

const FlightPayment = ({
    paymentIntent,
    onSuccess,
    onError,
    onBackClick,
    errorList = []
}) => {

    return <>
    <div className='flex justify-between'>
    <h5 className="text-xl font-bold mb-2">Payment</h5>
    <Link onClick={onBackClick}>Back</Link>
    </div>
    <div className='flex flex-col items-center'>
    {errorList.map((error, index) => {
        return <><div key={index} className='text-red-500'>{error}</div></>
    })}
    <div>Amount due <span className='font-bold'>
          {formatCurrency(paymentIntent.amount, paymentIntent.currency)}
        </span> </div>
    <CardPayment
        duffelPaymentIntentClientToken={paymentIntent.client_token}
        successfulPaymentHandler={onSuccess}
        errorPaymentHandler={onError}
    /></div></>
}

export default FlightPayment