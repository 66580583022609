import { formatDate, formatTime } from '../../Helpers/DateHelper';
import { getAirlineLogoUrl } from '../../Helpers/DuffelHelper';
import BaggageComponent from './BaggageComponent';
import './TicketCard.css';

const TicketCard = ({order}) => {

    return <div className="flex ticket font-sans justify-center items-center">
    <div className="w-full max-w-sm mx-4 shadow-lg rounded-lg">
    {order.slices.map((slice, index) => {
      return <div key={index}><div className="py-4 px-6 flex justify-between items-end bg-white rounded-lg rounded-b-none">
        <div>
          <img
            src={getAirlineLogoUrl(order.owner.iata_code)}
            alt={order.owner.name}
            width={24}
            height={24}
          />
        </div>
        <div>
          <span className="uppercase font-bold text-grey-darkest tracking-wide text-sm">Boarding pass</span>
        </div>
      </div>
      <div className="flex flex-col bg-red justify-around items-center py-16 px-6 sm:px-12 bg-destination">
        <div className='flex'>
            <div className="flex-1 text-5xl font-bold text-white tracking-wide text-shadow-lg">
                {slice.origin.iata_code}
            </div>
            <div className="mx-8">
            <img className="h-12" src="/airplane-icon.png" alt="" />
            </div>
            <div className="flex-1 text-right text-5xl font-bold text-white tracking-wide text-shadow-lg">
            {slice.destination.iata_code}
            </div>
        </div>
        <div className='text-white mt-4'>
            {slice.segments[0].stops?.length ? slice.segments[0].stops?.length + " Stops" : "Non Stop" } 
        </div>
      </div>
  
      
      <div className="flex bg-sky-500 px-6 sm:px-12 py-4">
        <div className="w-1/3">
          <span className="uppercase text-white block tracking-wide mb-1 font-thin text-xs">date</span>
          <span className="uppercase text-white block tracking-wide text-sm">{formatDate(slice.segments[0].departure_datetime)}</span>   
        </div>
        <div className="w-1/3">
          <span className="uppercase text-white block tracking-wide mb-1 font-thin text-xs">terminal</span>
          <span className="uppercase text-white block tracking-wide text-sm">{slice.segments[0].origin_terminal}</span>
        </div>
        <div className="w-1/3">
          <span className="uppercase text-white block tracking-wide mb-1 font-thin text-xs">boarding</span>
          <span className="uppercase text-white block tracking-wide text-sm">{formatTime(slice.segments[0].departure_datetime)}</span>
        </div>
      </div>
      <div className="py-8 px-6 sm:px-12 bg-white bg-plane rounded-lg rounded-t-none">

    {order.passengers.map((passenger, index) => {
        return  <div key={index}><div className="flex">
            <div className="w-3/5">
                <div className="mb-3">
                <h4 className="text-xs uppercase tracking-wide text-grey-darker">Passenger {index + 1}</h4>
                <p className="font-semibold tracking-wide text-white-grey-darkest passenger-name">{passenger.given_name} {passenger.family_name}</p>
                <p><small></small></p>
                </div>
            </div>
            <div className="small-3 columns">
                <div className="mb-3">
                <h4 className="text-xs uppercase tracking-wide text-grey-darker">Seat</h4>
                <p className="font-semibold tracking-wide text-grey-darkest">{slice.segments[0].passengers[index].seat ? slice.segments[0].passengers[index].seat : "Unknown"}</p>
                </div>
            </div>
            </div>
            <div className=''>
                <h4 className="text-xs uppercase tracking-wide text-grey-darker">Passenger ID</h4>
                <p className='font-semibold tracking-wide text-grey-darkest text-sm'>{slice.segments[0].passengers[index].passenger_id}</p>
            </div>
            <BaggageComponent baggages={slice.segments[0].passengers[index].baggages} />
        </div>
    })}

    <div className="flex mt-4">
        <div className="w-3/5">
            <div className="mb-3">
            <h4 className="text-xs uppercase tracking-wide text-grey-darker">Flight</h4>
            <p className="font-semibold tracking-wide text-white-grey-darkest passenger-name">{slice.segments[0].operating_carrier_flight_number}</p>
            <p><small></small></p>
            </div>
        </div>
        <div className="small-3 columns">
        <div className="mb-3">
            <h4 className="text-xs uppercase tracking-wide text-grey-darker">Duration</h4>
            <p className="font-semibold tracking-wide text-grey-darkest">{slice.segments[0].duration}</p>
        </div>
        </div>
    </div>

    

    {slice.segments[0].stops?.length ? <div className=''>
        <h4 className="text-xs uppercase tracking-wide text-grey-darker">Stops</h4>
        {slice.segments[0].stops?.map((stop, index) => {

            return <div key={index}><div className="flex mt-3">
            <div className="w-3/5">
                <div className="mb-3">
                <h4 className="text-xs uppercase tracking-wide text-grey-darker">Stop {index + 1}</h4>
                <p className="font-semibold tracking-wide text-white-grey-darkest passenger-name">{stop.airport.iata_code}</p>
                <p><small></small></p>
                </div>
            </div>
            <div className="small-3 columns">
                <div className="mb-3">
                    <h4 className="text-xs uppercase tracking-wide text-grey-darker">Duration</h4>
                    <p className="font-semibold tracking-wide text-grey-darkest">{stop.duration}</p>
                </div>
            </div>
            </div>

            <div className=''>
                <h4 className="text-xs uppercase tracking-wide text-grey-darker">Stop {index +1} Airport</h4>
                <p className='font-semibold tracking-wide text-grey-darkest text-sm'>{stop.airport.name}</p>
            </div>
            <div className='mt-2'>
                <h4 className="text-xs uppercase tracking-wide text-grey-darker">Stop {index +1} Departing at</h4>
                <p className='font-semibold tracking-wide text-grey-darkest text-sm'>{formatDate(stop.departing_at)} {formatTime(stop.departing_at)}</p>
            </div>
            </div>

        })}
        
        </div> : <></>}

    </div>
    </div>})}
      
    </div>
  </div>
  

}

export default TicketCard