import React from 'react';

function MainButton({ onClick, disabled = false, text = "Next", fullWidth = false }) {

  return (
    <button className={'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' + (fullWidth ? ' w-full': '')} onClick={() => {
        if(!disabled) {
          onClick()
        }
      }}>
      {text}
    </button>
  );
}

export default MainButton;
