import React from "react";
import FoursquarePlaceAutoCompleteComponent from "./FoursquarePlaceAutoCompleteComponent";

const Destination = ({ 
    destination,
    setDestination,
    destinationError,
    departure,
    setDeparture,
    departureError,
    tripType = 0
 }) => {

    const onDepartureInputChange = (event) => {
        console.log(event);
        setDeparture(event);
    }

    const onDestinationChange = (event) => {
        setDestination(event);
    }

    if(tripType == 0) return <div className='mb-6'><FoursquarePlaceAutoCompleteComponent label="Departure" placeholder="Enter location" error={departureError} value={departure} onChange={onDepartureInputChange} /></div>

    return (
        
        <div className='mb-6'>
            <div className='flex flex-row gap-4'>
                <div className="basis-1/2">
                <FoursquarePlaceAutoCompleteComponent label="Departure" placeholder="Enter location" error={departureError} value={departure} onChange={onDepartureInputChange} />
                </div>
                <div className="basis-1/2">
                <FoursquarePlaceAutoCompleteComponent label="Destination" placeholder="Enter location" error={destinationError} value={destination} onChange={onDestinationChange} />
                </div>
            </div>
       </div>
    )
}

export default Destination;
