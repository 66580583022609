import React, { useState, useEffect } from 'react'
import styles from "./ActivityPreferences.module.css"
import { Icon } from '@material-ui/core';

const SingleActivity = ({ activityName, icon, preferencesAttributes }) => {
    const { activitiesPreferences, setActivitiesPreferences } = preferencesAttributes;
    const [ isMarked, setIsMarked ] = useState(false);


    useEffect(() => { 
        const activityObject = activitiesPreferences[activityName];
        setIsMarked(activityObject.isMarked)
    },[])

    const togglePreference = () => {
        const updatedPreferences = {...activitiesPreferences};
        updatedPreferences[activityName] = {...preferencesAttributes[activityName], isMarked:!isMarked}
        setActivitiesPreferences(updatedPreferences)
        setIsMarked(!isMarked)
    }

    return(
        <div className={styles.iconContainer} onClick={togglePreference}>
            <Icon className={styles.icon} color={isMarked ? 'primary' :'disabled'} style={{ height:'32px'}}>{icon}</Icon>
            <p>{activityName}</p>
        </div>
    )

}

export default SingleActivity;