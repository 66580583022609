import React, {useState} from 'react'
import SingleTripRow from './SingleTripRow';

const TripList = ({tripsList}) => {


    return (
        <div className='flex flex-col gap-4'>
            {tripsList?.map((tripElement,index) =>
                <div key={index} className='p-6 bg-white border border-gray-200 rounded-lg shadow '> 
                    <SingleTripRow key={index} trip={tripElement} />
                </div>
            )}
        </div>
    )

}

export default TripList;