import { Route, Routes, Link, useLocation } from "react-router-dom"
import { useState } from "react";
import menu from '../Images/menu.svg'
import logo from '../Images/logo.png'
import SocialLoginSuccess from '../Pages/SocialLoginSuccess'
import LogoComponent from "./LogoComponent";
import { formatCurrency } from '../Helpers/StringHelper';
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios";

import { formatDate, formatTime } from '../Helpers/DateHelper';

const NavBar = () => {
   
    const user = useSelector((store) => store.currentUser);
    const location = useLocation();


    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const logOut = () => {
        window.open(process.env.REACT_APP_SERVER_URL+'auth/logout','_self')
    }

    const navItemClass = (route) => {
        if(route === location.pathname)
            return 'hover:opacity-50 block py-2 pl-3 pr-4 text-black md:text-white rounded md:bg-transparent underline'
        else
            return 'hover:opacity-50 block py-2 pl-3 pr-4 text-black md:text-white rounded'
    }

    const toggleMobile = () => {
        setShowMobileMenu(!showMobileMenu)
    }

    const rightSideRendering = () => {
        if(user.isLogged){
            return(
                <>
                <div className="flex text-black md:text-white gap-3">
                        <img className="w-10 h-10 rounded-full" src={user.picture}/>
                        <div>
                        {user.firstName} {user.lastName}
                        {user.balance > 0 ? <div className="text-xs">Balance: {formatCurrency(user.balance, "USD")}</div> : <></>}
                        <div className="hover:opacity-50 text-black md:text-white text-xs cursor-pointer" onClick={logOut}>
                            Logout
                        </div>
                        {user.activeUntil != null ?
                        <div className="text-xs">Active until: {formatDate(user.activeUntil)} {formatTime(user.activeUntil)}</div> : <></>}
                        </div>
                    </div>    
                </>
                )
        }
        return <Link to="/sign-up" className={navItemClass('/sign-up')}>Sign up</Link>

    }

    const navRendering = () => {
        if(user.isLogged){
            return(
                <ul className="flex bg-transparent flex-col font-medium p-4 md:p-0 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0">
                    <li>
                        <Link to="/" className={navItemClass('/')} aria-current="page">Home</Link>
                    </li>
                    <li>
                        <Link to="/my-trips" className={navItemClass('/my-trips')}>My Trips</Link>
                    </li>
                    <li>
                        <Link to="/contact" className={navItemClass('/contact')}>Contact</Link>
                    </li>
                    <li>
                                {rightSideRendering()}
                    </li>
                </ul>
                )
        }
        return (
            <ul className="flex bg-transparent flex-col font-medium p-4 md:p-0 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0">
                <li>
                    <Link to="/" className={navItemClass('/')} aria-current="page">Home</Link>
                </li>
                <li>
                    <Link to="/contact" className={navItemClass('/contact')}>Contact</Link>
                </li>
                <li>
                                {rightSideRendering()}
                </li>
            </ul>
        )
    }

    return (
        <>
            <nav className="hideOnDemand border-gray-200">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <LogoComponent />
                    <div className="flex md:order-2 justify-end" style={{minWidth:160}}>
                        <button data-collapse-toggle="navbar-cta" onClick={toggleMobile} type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden bg-gray-100 outline-none ring-2 ring-gray-200" aria-controls="navbar-cta" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <img height={30} width={30} src={menu} alt="" />
                        </button>
                    </div>
                    <div className={`items-center justify-between ${showMobileMenu ? "" : "hidden"} w-full md:flex md:w-auto md:order-1`} id="navbar-cta">
                        <ul className="flex bg-transparent flex-col font-medium p-4 md:p-0 mt-4 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 bg-white md:bg-transparent">
                            {navRendering()}
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default NavBar;