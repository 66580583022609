export function formatDate(date) {

    const dateObj = new Date(date);

    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const seconds = dateObj.getSeconds().toString().padStart(2, '0');
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;

}


export function formatTime(datetime) {

    const date = new Date(datetime);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes} ${ampm}`;

}

export function isAdult(date) {
    // Create a Date object from the input date string
  const inputDate = new Date(date);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in milliseconds between the current date and the input date
  const differenceMs = currentDate - inputDate;

  // Calculate the age in years
  const age = Math.floor(differenceMs / 1000 / 60 / 60 / 24 / 365.25);

  // Check if the age is greater than or equal to 18
  return age >= 12;
}

export function daysFromDates(date_1, date_2) {
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
}

export function dayFromDate(date) {
    const myDate = new Date(date);
    return myDate.getDate();
}

export function getShortMonthName(date) {
    const myDate = new Date(date);
    return myDate.toLocaleString('default', { month: 'short' });
}

export function getFriendlyHourMinutes(date) {
    const myDate = new Date(date); // Current date and time
    const hours = myDate.getHours().toString().padStart(2, '0'); // Get hours and pad with leading zero if necessary
    const minutes = myDate.getMinutes().toString().padStart(2, '0'); // Get minutes and pad with leading zero if necessary

    return `${hours}:${minutes}`;
}

export function getFriendlyTotalTime(date, todate) {
    const startDate = new Date(date); // Start date and time
    const endDate = new Date(todate); // End date and time

    const timeDifference = endDate - startDate; // Difference in milliseconds

    // Convert time difference to hours and minutes
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);

    // Format hours and minutes as HH:mm
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

export function isDateInThePast(date) {
    var currentDate = new Date();
    var expirationDate = new Date(date);

    // Compare the two dates
    if (expirationDate < currentDate) {
        return true
    } else {
        return false
    }
}