
import Layout from "../Components/Layout/Layout";
import SignUpComponent from "../Components/SignUpComponent";
import { fetchUser, userLogin } from "../redux/actions/currentUserAction";
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState, useRef } from "react";
import {useNavigate} from 'react-router-dom';

const SignUp = () => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.currentUser);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    
    return <Layout>
        <div className='mt-6 flex flex-col container mx-auto items-center justify-center inset-x-0 absolute z-10'>
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-96">
            {!user.isLogged ? <>    
            <h4 className="text-2xl font-bold">Sign up</h4>
                <SignUpComponent setIsLoading={setIsLoading} isLoading={isLoading} onSuccess={async () => {
                    const user = await fetchUser();
                    dispatch(userLogin(user));
                    navigate("/");
                }}></SignUpComponent></> : <h4 className="text-2xl font-bold">Welcome {user.name}</h4>}
            </div>
        </div>
    </Layout>
}
export default SignUp;