import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import FormLabelComponent from './FormLabelComponent';
import FormInputComponent from './FormInputComponent';
import { formatDate } from '../Helpers/DateHelper';
import AppModal from './AppModal';
import FormInputError from './FormInputError';

function DateRangePickerComponent({ dateRange, setDateRange, error }) {

  const [ modalShow, setModalShow ] = useState(false);

  const handleSelect = (ranges) => {
    setDateRange(ranges.selection);
  };

  const getFromDate = () => {
    if(dateRange.startDate === null) return "";
    return formatDate(dateRange.startDate);
  }

  const getToDate = () => {
    if(dateRange.endDate === null) return "";
    return formatDate(dateRange.endDate);
  }

  const getSelectedRange = () => {
    if(dateRange.startDate === null && dateRange.endDate === null) return "";
    return formatDate(dateRange.startDate)+" - "+formatDate(dateRange.endDate);
  }

  
  return (
    <div className="mb-6">
      <FormLabelComponent error={error} >Dates</FormLabelComponent>
      <div className='columns-2'>
      <FormInputComponent error={error} onClick={() => {setModalShow(true)}} value={getFromDate()} readonly={true} placeholder={"Select departure"}/>
      <FormInputComponent error={error} onClick={() => {setModalShow(true)}} value={getToDate()} readonly={true} placeholder={"Select retrun"}/>
      </div>
      <FormInputError error={error} />
      <AppModal onClose={() => { setModalShow(false) }} size='max-w-sm' showModal={modalShow} title={"Select trip range"}>
        <div className='flex flex-col'>
        <DateRangePicker
          ranges={[dateRange]}
          onChange={handleSelect}
        />
        <button onClick={() => {setModalShow(false)}} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Done
        </button>
        </div>
      </AppModal>
    </div>
  );
}

export default DateRangePickerComponent;
