const FormSelectComponent = ({
    options = [{ key: 'key', value: 'value' }],
    selectedOption = "",
    onClick = () => {},
    type = "text",
    onChange,
    id,
    error = ""
}) => {
    const getClasses = () => {
        if(error !== "")
            return "bg-gray-50 border border-red-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        else
            return "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    }

    const getOptions = () => {
        return options.map((val, key) => {
            return <option value={val.value} key={key}>{val.label}</option>
        })
    }
    return <>
        <select value={selectedOption} onChange={onChange} onClick={() => {onClick()}} type={type} id={id} className={getClasses()} >
            {getOptions()}
        </select>
    </>
}

export default FormSelectComponent;