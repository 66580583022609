import { forwardRef } from 'react';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import DatePicker from 'react-date-picker';

const FormDateComponent = forwardRef(({
    onChange,
    id,
    value,
    error = ""
}, ref) => {
    const getClasses = () => {
        if(error !== "")
            return "bg-gray-50 border border-red-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
        else
            return "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
    }
    return <DatePicker id='id' ref={ref} className={getClasses()} onChange={onChange} value={value} />
})

export default FormDateComponent;