import React, {useState, useEffect} from 'react';
import { Route, Routes, Link, Navigate } from "react-router-dom"
import './App.css';
import Layout from './Components/Layout/Layout';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Contact from './Pages/Contact';
import Home from './Pages/Home';
import Results from './Pages/Results'
import SocialLoginSuccess from './Pages/SocialLoginSuccess';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUser, userLogin } from "./redux/actions/currentUserAction";
import SignUp from './Pages/SignUp';
import MyTrips from './Pages/MyTrips';
import TermsAndConditions from './Pages/TermsAndConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import PageNotFound from './Pages/PageNotFound';
import TripError from './Pages/TripError';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3b82f6'
    }
  }
});

// Define your components
const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      try{
        const user = await fetchUser();
        dispatch(userLogin(user));
      } catch(err) {
      console.log(err)
      }

    }
    fetch()
  }, [])

  return (
    <MuiThemeProvider theme={theme}>
        <Routes>
            <Route path="/" exact element={<Home/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/my-trips" element={<MyTrips/>} />
            <Route path="/results/:tripId" element={<Results/>} />
            <Route path="/social-login-success" element={<SocialLoginSuccess/>} />
            <Route path="/sign-up" element={<SignUp/>} />
            <Route path="/terms" element={<TermsAndConditions/>} />
            <Route path="/privacy" element={<PrivacyPolicy/>} />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="/trip-error" element={<TripError />} />
            <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
    </MuiThemeProvider>
  );
};

export default App;
