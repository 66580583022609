import React, { useEffect, useState } from 'react';
import LoaderComponent from '../LoaderComponent';
import PassengerForm from './PassengerForm';
import { BookingCard } from './BookingCard';
import axios from "axios";
import OfferList from './OfferList';
import { useDispatch, useSelector } from 'react-redux';
import { isValidEmail, validatePhoneNumber } from '../../Helpers/StringHelper';
import NumberOfTravelers from './NumberOfTravelers';
import FlightPayment from './FlightPayment';
import OrderSucceed from './OrderSucceed';
import { isAdult, isDateInThePast } from '../../Helpers/DateHelper';
import { passengerTypesToPassengers } from '../../Helpers/DuffelHelper';
import { updateBalance } from "../../redux/actions/currentUserAction";


const FlightsComponent = ({tripId, setOrders = (orders) => {}}) => {
  const [isFetching, setIsFetching] = useState(true);
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [order, setOrder] = useState(null);
  const [passengers, setPassengers] = useState([])
  const user = useSelector((store) => store.currentUser);
  const [passengerErrorList, setPassengerErrorList] = useState([])
  const [passengerTypes, setPassengerTypes] = useState([{type: 'adult'}])
  const [selectedNumberOfTravelers, setSelectedNumberOfTravelers] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [paymentErrorList, setPaymentErrorList] = useState([])
  const [succeededOrder, setSucceededOrder] = useState(null)


  const dispatch = useDispatch();

  const fetchOffers = async (passengerTypes) => {
    setIsFetching(true);

    try {
      const res = await axios.post(process.env.REACT_APP_SERVER_URL + 'api/flights/search/'+tripId, {passengerTypes}, {
         withCredentials:true
      });

      const { offers, errors, trip } = res.data

      if (errors) {
        console.log(errors)
        setIsFetching(false);
        return;
      }

      if (!offers.length) {
        console.log("no offers")
        setIsFetching(false);
        return;
      }
      setPassengers(passengerTypesToPassengers(passengerTypes, user))

      setOffers(offers);
    } catch (e) {
        console.log(e)
    }

    setIsFetching(false);
  };

  const updatePassenger = (index, key, val) => {
    const newPassengers = passengers.map(l => Object.assign({}, l));
    newPassengers[index][key] = val
    setPassengers(newPassengers)
  }

  const clearPassengerError = (passenger) => {
    passenger.firstNameError = ''
    passenger.lastNameError = ''
    passenger.genderError = ''
    passenger.birthdayError = ''
    passenger.emailError = ''
    passenger.phoneError = ''
    passenger.documentExpiresOnError = ''
    passenger.documentCountryError = ''
    passenger.documentTypeError = ''
    passenger.documentNumberError = ''

    return passenger
  }

  const validatePassengers = () => {
    let isValid = true;

    const newPassengers = passengers.map(l => Object.assign({}, l));
    let errors = []
    newPassengers.map((passenger, index) => {
      passenger = clearPassengerError(passenger)
      let passengerValid = true

      if(passenger.firstName.trim() == "") {
        passenger.firstNameError = "Please enter first name"
        passengerValid = false;
        isValid = false;
      }

      if(passenger.lastName.trim() == "") {
        passenger.lastNameError = "Please enter last name"
        passengerValid = false;
        isValid = false;
      }

      if(passenger.gender == "") {
        passenger.genderError = "Please select gender"
        passengerValid = false;
        isValid = false;
      }

      if(passenger.birthday == "") {
        passenger.birthdayError = "Please enter birthday"
        passengerValid = false;
        isValid = false;
      }

      if(passenger.type =="adult" && !isAdult(passenger.birthday)) {
        passenger.birthdayError = "Must be at least 18 years old"
        passengerValid = false;
        isValid = false;
      }

      if(passenger.type !="adult" && isAdult(passenger.birthday)) {
        passenger.birthdayError = "Children older than 12 are considered adults"
        passengerValid = false;
        isValid = false;
      }

      if(passenger.email == "") {
        passenger.emailError = "Please enter email"
        passengerValid = false;
        isValid = false;
      }
      if(!isValidEmail(passenger.email)) {
        passenger.emailError = "Please enter a valid email"
        passengerValid = false;
        isValid = false;
      }

      if(passenger.phone.trim() == "") {
        passenger.phoneError = "Please enter phone number"
        passengerValid = false;
        isValid = false;
      }

      if(!validatePhoneNumber(passenger.phone )) {
        passenger.phoneError = "Invalid phone number"
        passengerValid = false;
        isValid = false;
      }

      if(passenger.documentNumber.trim().length < 5) {
        passenger.documentNumberError = "Passport number too short"
        passengerValid = false;
        isValid = false;
      }

      if(passenger.documentNumber.trim() == "") {
        passenger.documentNumberError = "Please enter passport number"
        passengerValid = false;
        isValid = false;
      }

      if(passenger.documentCountry == "") {
        passenger.documentCountryError = "Please enter passport country"
        passengerValid = false;
        isValid = false;
      }

      if(passenger.documentExpiresOn == "") {
        passenger.documentExpiresOnError = "Expiry date must be provided"
        passengerValid = false;
        isValid = false;
      } else if(isDateInThePast(passenger.documentExpiresOn)) {
        passenger.documentExpiresOnError = "Passport already expired"
        passengerValid = false;
        isValid = false;
      }

      console.log(passenger)


      if(!passengerValid) {
        errors.push(`Check passenger ${index + 1} form`)
      }

      newPassengers[index] = passenger
    })
    setPassengerErrorList(errors)
    setPassengers(newPassengers)
    
    return isValid
  }

  const submitForm = async () => {
    if(!validatePassengers()) return;
    if(isFetching) return true;

    setIsFetching(true)
    try {
      const res = await axios.post(process.env.REACT_APP_SERVER_URL + 'api/flights/createPaymentIntent', 
      {
        offerId:selectedOffer.id,
        offer:selectedOffer,
        tripId: tripId,
        passengers: passengers
      }, {
        withCredentials:true
     });
     if(res.data.paymentIntent!=null) {
        setPaymentIntent(res.data.paymentIntent)
     } else {
        setSucceededOrder(res.data.order)
        dispatch(updateBalance(res.data.balance));
        setOrders(res.data.orders)
     }
    } catch(e) {
      setPassengerErrorList(["Could not complete operation, try again later"])
      console.log(e)
    }

    setIsFetching(false)
  }

  useEffect(() => {
  }, [])

  const numberOfTravelersSelected = (types) => {
    setPassengerTypes(types)
    fetchOffers(types)
    setSelectedNumberOfTravelers(true)
  }

  const onPaymentIntentSuccess = async () => {
    setIsFetching(true)

    try {
      const res = await axios.post(process.env.REACT_APP_SERVER_URL + 'api/flights/confirmPaymentIntent',
      {
        paymentIntentId:paymentIntent.id,
        offerId:selectedOffer.id,
        offer:selectedOffer,
        tripId: tripId,
        passengers: passengers
      },
      {
        withCredentials:true
     });

     setSucceededOrder(res.data.order)
     dispatch(updateBalance(res.data.balance));
     setOrders(res.data.orders)

    } catch(e) {
      setPassengerErrorList(["Could not complete operation, try again later"])
      setPaymentIntent(null)
      console.log(e)
    }

    setIsFetching(false)
  }

  const onPaymentIntentError = (err) => {
    setPaymentErrorList([err.message])
  }

  const resetForm = () => {
    setSelectedNumberOfTravelers(false)
    setSelectedOffer(null)
    setPaymentIntent(null)
    setPaymentErrorList([])
    setPassengerErrorList([])
  }

  if(succeededOrder !== null) return <OrderSucceed order={succeededOrder} />
  if(!selectedNumberOfTravelers) return <NumberOfTravelers onSelect={numberOfTravelersSelected}/>
  if(isFetching) return <LoaderComponent />
  if(paymentIntent != null) return <FlightPayment paymentIntent={paymentIntent} onSuccess={onPaymentIntentSuccess} onError={onPaymentIntentError} errorList={paymentErrorList} onBackClick={() => {setPaymentIntent(null)}}/>
  if(selectedOffer) return <PassengerForm passengerErrorList={passengerErrorList} onSuccess={submitForm} passengers={passengers} onBackClick={() => setSelectedOffer(null)} onChange={updatePassenger}/>
  return <OfferList onBackClick={resetForm} offers={offers} onClick={(offer) => {
    setPassengers(passengerTypesToPassengers(offer.passengers, user))
    setSelectedOffer(offer)
  }}/>
}

export default FlightsComponent;