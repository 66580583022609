import React, {useState ,useEffect } from 'react';
import axios from 'axios'
import Layout from '../Components/Layout/NoPlaneLayout';
import TripList from '../Components/TripsList/TripList';
import CategoriesNav from '../Components/TripsList/CategoriesNav';
import FooterMenu from '../Components/FooterMenu';
import LoaderComponent from '../Components/LoaderComponent';

const MyTrips = () => {

    const [ tripsList, setTripsList ] = useState([]);
    const [ categorySelected, setCategorySelected ] = useState(0);
    const [ isLoading, setIsLoading ] = useState(true);

    const categories = [
        {name:'Planned'},
        {name:'Scheduled'}
    ]


    useEffect(() => {
        const fetchAllTrips = async () => {
            try{
                const response = await axios.get(process.env.REACT_APP_SERVER_URL+'api/trip/fetchAll',{withCredentials:true})
                if(response) {
                    const trips = response.data;
                    setTripsList(trips)
                    setIsLoading(false)
                }

            } catch (err) {
                console.log(err);
                setIsLoading(false)
            }
        }
        fetchAllTrips();
    },[])


    return (
        <Layout>
            <div className='mt-6 flex flex-col container mx-auto items-center justify-center inset-x-0 absolute z-10'>
            <h2 className="text-white text-3xl mb-6 delay-2000 transition transition-opacity duration-1000">History</h2>
                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                {/*<CategoriesNav categories={categories}/>*/}
                {isLoading ? <LoaderComponent /> : tripsList.length >0 ? <TripList tripsList={tripsList} /> : <>No trips yet.</>}
                </div>
        <FooterMenu />
            </div>
        </Layout>
    )
    
}

export default MyTrips;