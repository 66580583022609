import React, { useState, useEffect } from "react";
import ReactMapGL, {GeolocateControl, Marker, Popup} from "react-map-gl";


const MapBox = ({markers, placeImageCompoent}) => {

  const [isReady, setIsReady] = useState(false)
  const [popupInfo, setPopupInfo] = useState(null);

  const defaultView = {
    zoom: 11,
    width:"100vw",
    height:"100vh"
  };
  

  const [viewport, setViewport] = useState(defaultView);

  const findInitLocation = () => {
   for (let index = 0; index < markers.length; index++) {
      const { latitude, longitude} = markers[index];
      if(latitude && longitude) {
        return markers[index];
      }
   }
  }

  useEffect(()=>{
    if(markers && markers.length > 0){
      const initLocation = findInitLocation();
      setViewport(v => { return {...v, ...initLocation}});
      setIsReady(true)
    }
  }, [markers])

  const generateMarkerIcon = (type) => {
    switch(true) {
      case type.includes('Sightseeing'):
        return <>👓</>
      case type.includes('Landmark'):
        return <>👓</>
      case type.includes('Museum'):
        return <>🏛</>
      case type.includes('Lunch'):
        return <>🍔</>
      case type.includes('Shopping'):
        return <>🛒</>
      case type.includes('Dinner'):
        return <>🍔</>
      case type.includes('Park'):
        return <>🏞️</>
      case type.includes('Restaurant'):
        return <>🍔</>
      case type.includes('Entertainment'):
        return <>🎥</>
      case type.includes('Music Venue'):
        return <>🎶</>
      case type.includes('Bar'):
        return <>🍸</>
      case type.includes('Club'):
        return <>🕺</>
      default:
          return <>📌</>

    }
  }

  return isReady ? <ReactMapGL
      mapStyle='mapbox://styles/mapbox/outdoors-v12'
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      initialViewState={viewport}
    >
      {markers.map((place, index) => ((place.latitude && place.longitude) &&
        <div key={index}>
        <Marker key={index} latitude={place.latitude} longitude={place.longitude} onClick={(e) => {
          e.originalEvent.stopPropagation();
          //place.onClick()
          setPopupInfo(place)
        }}>
          <div className="max-w-sm p-2 text-lg bg-white border border-gray-200 rounded-lg shadow">
            {generateMarkerIcon(place.type.trim())}
            </div>
        </Marker></div>
      ))}
      {popupInfo && (
          <Popup
            anchor="top"
            longitude={Number(popupInfo.longitude)}
            latitude={Number(popupInfo.latitude)}
            onClose={() => setPopupInfo(null)}
            closeButton={false}
          >
              {placeImageCompoent(popupInfo)}
          </Popup>
        )}
    </ReactMapGL> : <></>
  ;
}

export default MapBox;