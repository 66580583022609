
import { Link } from '@material-ui/core'
import { BookingCard } from "./BookingCard";

const OfferList = ({offers, onClick = (offer) => {}, onBackClick = () => {}}) => {

    if(offers.length > 0) return <>
    <div className='flex justify-between'>
    <h5 className="text-xl font-bold mb-2">Select Offer</h5>
    <Link onClick={onBackClick}>Back</Link>
    </div>
    <div className="flex flex-col gap-4">
    {offers.map((offer, index) => <BookingCard
        key={index}
        offer={offer}
        onClick={(offer) => {
            onClick(offer);
        }}
    />)}</div></>
    else return <>
    <div className='flex justify-between'>
    <h5 className="text-xl font-bold mb-2">Select Offer</h5>
    <Link onClick={onBackClick}>Back</Link>
    </div>
    Could not find offers, please try again later.</>

}

export default OfferList