import React from 'react'
import styles from "./Weather.module.css";
import { formatDate } from '../../Helpers/DateHelper';

const SingleDayWeather = ({dailyWeather}) => {
    
    const { day, date } = dailyWeather;
    const { condition } = day;
    const { text, icon } = condition;

    return(
        <div className={styles.dayContainer}>
            <img style={{width:'45px', height:'auto'}} src={icon} />
            <span className='text-xs'>{formatDate(date)}</span>
            <span className='text-xs text-center'>{text}</span>
        </div>
    )
}

export default SingleDayWeather;