
import libphonenumber from 'google-libphonenumber';

export function isValidEmail(str) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email against the pattern
  return emailPattern.test(str);
}

export function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function createSessionToken() {
  return Date.now()+makeid(19)
}

export const formatCurrency = (value, currency) => {
  return Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency,
  }).format(+value);
};

export const validatePhoneNumber = (phoneNumber) => {
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  try {
    const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(phoneNumber);
    const isValid = phoneUtil.isValidNumber(parsedPhoneNumber);
    return isValid;
  } catch (error) {
    return false;
  }
}

export const kayakLinkFormat = (trip, link) => {
  let number = "1";
  if(trip.travelWith == "with a partner") {
    number = "2"
  } else if(trip.travelWith == "with family") {
    number = "3"
  }
  const fixedLink = link.replace("{origin}", trip.departureAirport.code)
  .replace("{destination}", trip.destinationAirport.code)
  .replace("{departure}", trip.departureDate)
  .replace("{return}", trip.returnDate)
  .replace("{number}", "1")
  return fixedLink;
}