const FormLabelComponent = ({
    error = "",
    forHtml = "email",
    children,
}) => {
    const getClasses = () => {
        if(error !== "")
            return "block mb-2 text-sm font-medium text-red-500"
        else
            return "block mb-2 text-sm font-medium text-gray-900"
    }
    return <label htmlFor={forHtml} className={getClasses()}>{children}</label>
}

export default FormLabelComponent;