import React from "react";

const AppModal = ({
    showModal = false,
    onClose = () => {},
    size = 'max-w-3xl',
    title = "",
    children
}) => {
  return (
    <>
      {showModal ? (
        <>
          <div
            className="justify-center overflow-x-hidden overflow-y-auto items-center flex fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className={"relative w-auto my-6 max-h-full mx-auto w-full "+size}>
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold">
                    {title}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => onClose()}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                    {children}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default AppModal