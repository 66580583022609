import logo from '../Images/logo.png'
import logob from '../Images/logo-b.png'
import { Link } from "react-router-dom"

const LogoComponent = ({
    black = false
}) => {
    return <Link to="/" className="flex items-center">
        <img src={black?logob:  logo} alt="" style={{width: 160}}></img>
    </Link>
}

export default LogoComponent;