export const getAirlineLogoUrl = (iataCode) =>
  `https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/${iataCode}.svg`;


export const getUpdatedSum = (sum) => {
  return (((parseFloat(sum) + 1.00 + (parseFloat(sum) * 0.05)) ) / (1 - 0.029)).toFixed(2)
}

export const passengerTypesToPassengers = (passengerTypes, user) => {
  const travelersArray = []
      
    passengerTypes.map((traveler, index) => {
        travelersArray[index] = {
          firstName: index == 0 ? user.firstName : '',
          firstNameError: '',
          lastName: index == 0 ? user.lastName : '',
          lastNameError: '',
          gender: '',
          genderError: '',
          birthday: '',
          birthdayError: '',
          email: index == 0 ? user.email : '',
          emailError: '',
          phone: '',
          phoneError: '',
          type: traveler.type,
          documentExpiresOn: '',
          documentExpiresOnError: '',
          documentCountry: '',
          documentCountryError: '',
          documentType: 'passport',
          documentTypeError: '',
          documentNumber: '',
          documentNumberError: '',
      }
    })

    return travelersArray
}