import React, { useState } from 'react'
import {
    GoogleLoginButton, TwitterLoginButton, FacebookLoginButton
  } from 'react-social-login-buttons'
import LoaderComponent from './LoaderComponent'
import FormLabelComponent from './FormLabelComponent'
import Spacer from './Spacer'
import axios from 'axios'
import { Link } from 'react-router-dom'
const SignUpComponent = ({
  onSuccess = (profile) => {},
  isLoading = false,
  setIsLoading = () => {},
  error = "",
  setError = () => {}
}) => {
    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState(null)


    const getAuth = async (provider) => {
      setIsLoading(true)
      try{
        const win = window.open(process.env.REACT_APP_SERVER_URL + 'auth/login/'+provider,'targetWindow',
        `toolbar=no,
         location=no,
         status=no,
         menubar=no,
         scrollbars=yes,
         resizable=yes,
         width=450,
         height=600`);

         var timer = setInterval(function() { 
            if(win.closed) {
                clearInterval(timer);
                onSuccess({})
            }
        }, 500);

      } catch(err) {
        console.log(err)
        setIsLoading(false)
      }
    }

    return (
        <>
          {isLoading ? (
            <div className="w-full flex flex-col justify-center items-center">
            <LoaderComponent />
            </div>
          ) : (
            <div className={`App ${provider && profile ? 'hide' : ''}`}>
              <FormLabelComponent>Please sign up to continue</FormLabelComponent>
              <Spacer height={20} />
              {/* <LoginSocialGoogle
                client_id={process.env.REACT_APP_GG_APP_ID || ''}
                scope={'email'}
                onLoginStart={() => {
                    setError("")
                    setIsLoading(true)
                }}
                onResolve={({ provider, data }) => {
                  setProvider(provider)
                  setProfile(data)
                  onSuccess(data)
                }}
                onReject={(err) => {
                  console.log(err)
                }}
              >
              </LoginSocialGoogle> */}
              <GoogleLoginButton onClick={()=>getAuth('google')} />
              {/* <FacebookLoginButton onClick ={()=>getAuth('facebook')}/> */}
              {/* <TwitterLoginButton onClick ={()=>getAuth('twitter')}/> */}
              <p className='mt-4'>By signin up you agree to our <Link className='underline' target='_new' to="/terms">Terms and conditions</Link> & <Link className='underline' target='_new' to="/privacy">Privacy policy</Link></p>
            </div>
          )}
        </>)
}

export default SignUpComponent;