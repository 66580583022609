import { useState } from 'react';
import { formatCurrency } from '../../Helpers/StringHelper';
import { getAirlineLogoUrl, getUpdatedSum } from '../../Helpers/DuffelHelper';
import { dayFromDate, getFriendlyHourMinutes, getFriendlyTotalTime, getShortMonthName } from '../../Helpers/DateHelper';
import BaggageComponent from './BaggageComponent';

export const BookingCard = ({ offer, onClick = (offer) => {} }) => {

  return (
    <>
    <div onClick={() => onClick(offer)} className="p-6 bg-white border border-gray-200 cursor-pointer hover:border-blue-400 rounded-lg shadow gap-4 flex flex-col">
      <div className='flex justify-between'>
        <div className='flex gap-2'>
          <span className='font-bold'>
            {formatCurrency(getUpdatedSum(offer.total_amount), offer.total_currency)}
          </span>
          <span className="text-xs inline-flex items-center leading-sm uppercase px-3 py-1 bg-gray-200 rounded-full">
            {offer.slices[0].segments[0].passengers[0].cabin_class_marketing_name}
          </span>
        </div>
        <BaggageComponent baggages={offer.slices[0].segments[0].passengers[0].baggages} />
      </div>
    {offer.slices.map((slice, index) => {
      return <div key={index} className="p-4 items-center bg-white border border-gray-200 rounded-sm shadow flex flex-row gap-4 flex-wrap">
          <div className='flex flex-col items-center text-gray-400'>
            <div className='text-sm font-bold'>{dayFromDate(slice.segments[0].departing_at)}</div>
            <div className='uppercase text-xs'>{getShortMonthName(slice.segments[0].departing_at)}</div>
          </div>
          <img
            src={getAirlineLogoUrl(offer.owner.iata_code)}
            alt={offer.owner.name}
            width={24}
            height={24}
          />
          <div className='flex flex-1 items-center gap-4'>
          <div className='flex flex-col items-center'>
            <span className='font-bold text-sm'>{getFriendlyHourMinutes(slice.segments[0].departing_at)}</span>
            <span className='text-xs text-gray-600'>{slice.origin.iata_code}</span>
          </div>
          <div className='flex flex-1 flex-col items-center'>
            <span className='text-xs text-green uppercase'>{slice.segments[0].stops?.length ? slice.segments[0].stops.length:"Non"} Stop</span>
            <span className="h-px my-2 w-full bg-gray-200"></span>
            <span className='text-gray-700 text-sm'>{getFriendlyTotalTime(slice.segments[0].departing_at, slice.segments[0].arriving_at)}</span>
          </div>
          <div className='flex flex-col items-center'>
            <span className='font-bold text-sm'>{getFriendlyHourMinutes(slice.segments[0].arriving_at)}</span>
            <span className='text-xs text-gray-600'>{slice.destination.iata_code}</span>
          </div>
          </div>
      </div>
    })}
    </div>
    </>
  );
};
