import LoaderComponent from "./LoaderComponent";

const TripDestinationSuggestionSelector = ({
    isLoading = false,
    tripDestinationSelection = [],
    setTripDestination = () => {}
}) => {

    const DestinationComponent = (destination, index) => {
        return <div key={index} onClick={() =>{ console.log(destination); setTripDestination(destination) }} style={{background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/destinations/${destination.id}.jpeg)`, backgroundSize: 'cover', backgroundPosition: 'center'}} className="cursor-pointer flex items-end type-selector rounded-lg flex-1 p-4 text-white font-bold type-overlay">
            <p className="drop-shadow-lg">{destination.city}, {destination.name}</p>
        </div>
    }

    if(isLoading) return <LoaderComponent />
    if(tripDestinationSelection.length == 0) return <>No activities found for this date and activity, please go back and try another</>

    return <>
    <h2 className="mb-4">Suggested destinations</h2>
    <div className="destionation-suggestion flex-wrap flex w-full gap-4 mb-4">
        {tripDestinationSelection.map((destination, index) => {
            return DestinationComponent(destination, index)
        })}
    </div></>
}

export default TripDestinationSuggestionSelector;