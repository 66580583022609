import React from 'react'
import {useNavigate} from 'react-router-dom';
import { formatDate } from '../../Helpers/DateHelper';
import MainButton from '../MainButton';



const SingleTripRow = ({ trip, isSelected }) => {
    const navigate = useNavigate();

    const {
        departureLocation, destination,
        departureDate, returnDate,
        travelWith
    } = trip;

    const showTripHandler = () => {
        console.log("navigate");
        navigate("/results/"+trip.id)
    }

    return (
        <article className="">
            <div className="flex justify-between items-center gap-4" >
                <div>
                    <div className='flex flex-col'>
                        <div className='text-xs	text-gray-500'>Departure</div>
                        <div className=''>{departureLocation}</div>
                    </div>
                    <div className='flex flex-col'>
                        <div className='text-xs	text-gray-500'> Destination  </div>
                        <div className=''>{destination}</div>
                    </div>
                </div>
                <div>
                    <div className='flex flex-col'>
                        <div className='text-xs	text-gray-500'> From date  </div>
                        <div className=''>{formatDate(departureDate)}</div>
                    </div>
                    <div className='flex flex-col'>
                        <div className='text-xs	text-gray-500'> To date  </div>
                        <div className=''>{formatDate(returnDate)}</div>
                    </div>
                </div>

                <div className='capitalize'>
                    {travelWith}
                </div>

                <MainButton text="Show Trip" onClick={showTripHandler}>
                </MainButton>
            </div>
        </article>
    )

}

export default SingleTripRow;