import { forwardRef } from 'react';

const FormTextAreaComponent = forwardRef(({
    readonly,
    placeholder,
    onClick = () => {},
    onChange,
    rows = 6,
    id,
    value,
    error = ""
}, ref) => {
    const getClasses = () => {
        if(error !== "")
            return "bg-gray-50 border border-red-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        else
            return "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    }
    return <textarea ref={ref} rows={rows} onChange={onChange} onClick={() => {onClick()}} id={id} value={value} className={getClasses()} placeholder={placeholder} readOnly={readonly} />
})

export default FormTextAreaComponent;