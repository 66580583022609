import React from 'react'
import Layout from '../Components/Layout/Layout'
import { Link } from 'react-router-dom'
import FooterMenu from '../Components/FooterMenu'


const TermsAndConditions = () => {

    return(
        <Layout>
            <div className='mt-6 flex flex-col container mx-auto items-center justify-center inset-x-0 absolute z-10'>

            <h2 className="text-white text-3xl mb-6 delay-2000 transition transition-opacity duration-1000">Terms and conditions</h2>
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className='gap-4 flex flex-col' style={{maxWidth:750}}>
        <p>

    1. Acceptance of Terms
    This Terms and Conditions Agreement (the “Agreement”) is a legally binding agreement between you and TravelMind, concerning your access to and use of the TravelMind website (the “Website”) and the services and products offered by TravelMind (collectively, the “Services”). By using the Services, you accept and agree to be bound by this Agreement. If you do not agree to these terms, you may not access or use the Services.
        </p>
        <p>
    2. Privacy Policy
    Your privacy is important to us. Please take the time to review our Privacy Policy, which is incorporated by reference into this Agreement. 
    </p>
        <p>
    3. Affiliates
    The Services may include links to third-party websites, services, and applications, including those of our affiliates, such as Skyscanner, Expedia, and Booking.com (collectively, “Affiliates”). We are not responsible for any content, products, or services provided by any Affiliates. You are solely responsible for any transactions or interactions with Affiliates.
    </p>
        <p>
    4. Prohibited Conduct
    You agree not to use the Services for any unlawful or prohibited purpose, or in any manner that could damage, disable, overburden, or impair the Services or interfere with any other party’s use and enjoyment of the Services. You are solely responsible for any content or information you post or transmit through the Services.
    </p>
        <p>
    5. Disclaimer of Warranties
    THE SERVICES ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE OR SAFE, OR THAT THEY WILL MEET YOUR REQUIREMENTS.
    </p>
        <p>
    6. Limitation of Liability
    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, EXEMPLARY, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY RELATED TO THE USE OF THE SERVICES.
    </p>
        <p>
    7. Indemnification
    You agree to indemnify and hold us harmless from any and all claims, losses, costs, damages, liabilities, and expenses (including attorneys’ fees) arising out of or related to your use of the Services or breach of this Agreement.
    </p>
        <p>
    8. Termination
    We may terminate this Agreement immediately if you breach any term of this Agreement. Upon termination, you must cease use of the Services.
    </p>
        <p>
    9. Miscellaneous
    This Agreement is governed by the laws of the State of New York, without reference to conflict of laws principles. The exclusive jurisdiction for any claim, action, or dispute with us or relating in any way to your use of the Services will be in the state and federal courts located in New York. This Agreement is the entire agreement between you and us regarding the Services, and supersedes any prior agreements. If any provision of this Agreement is held to be invalid or unenforceable, such provision will be struck and the remaining provisions will be enforced. You may not assign or transfer this Agreement or your rights hereunder, in whole or in part, by operation of law or otherwise, without our prior written consent. 
    </p>
        <p>
    10. Questions
    Questions or comments about the Services may be directed to us at the <Link className='underline underline-offset-1' to="/contact">Contact us form</Link>.
        </p>
    <p>
    11. Cancellation of a transaction in accordance with the Consumer Protection (Cancellation),
     Htsa"a -2010 and Consumer Protection Act, 1981.
    </p>
        </div>
        </div>
        <FooterMenu />
        </div>
        </Layout>
    )

}

export default TermsAndConditions