import React from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import FormLabelComponent from './FormLabelComponent';
import Spacer from './Spacer';

const ActivityDensity = ({activityDesnisty, setActivityDesnisty}) => {

  const handleChange = (newValue) => {
    setActivityDesnisty(newValue);
    // Do something with the new value, such as updating state or calling a callback function
  };

  return (
    <div className="mb-6">
      <FormLabelComponent>Activity Density:</FormLabelComponent>
      <Spacer height={20} />
      <InputRange
        minValue={1}
        maxValue={5}
        value={activityDesnisty}
        onChange={handleChange}
      />
      <Spacer height={30} />
    </div>
  );
};

export default ActivityDensity;
